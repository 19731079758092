<template>
  <div class="recome">
    <Top></Top>
    <Fix></Fix>
    <div class="recome-box" ref="recomeBox">
      <div class="recome-middle">
        <div class="recome-middle-top">
          <div>AI商标注册</div>
          <img :src="require('/static/images/xiaoguo.png')" alt="">
        </div>
        <div class="recome-name" v-show="nameshow">
          <div class="recome-name-title">请输入你要申请的商标名称</div>
          <div class="recome-name-picktype">
            <div class="recome-name-picktype-detail">
              <!-- <div class="recome-pickwho" v-show="this.$cookie.getCookie('type') == 3">
                <div class="recome-pickwho1">
                  <span>下单人</span>
                  <input v-model.trim="person" type="text" maxlength="11" placeholder="请输入下单人手机号">
                </div>
                <div class="trigonometry" v-show="personshow"></div>
                <div class="recome-pickwho2" v-show="personshow">
                  <div class="recome-pickhome-phone" v-for="(v,k) in personArr" :key="k" @click="addname(v.userMobile)">
                    {{ v.userMobile }}
                  </div>
                </div>
              </div> -->
              <div class="detail-div1">
                <span>商标类型</span>
                <input value="1" v-model="picked1" name="type1" type="radio"><span>文字商标</span>
                <input value="2" v-model="picked1" name="type1" type="radio"><span>图形商标</span>
                <input value="3" v-model="picked1" name="type1" type="radio"><span>图文组合</span>
              </div>
              <div class="detail-div2" v-if="picked1 == '1'">
                <div class="detail-div2-writename">
                  <span>商标名称</span>
                  <input type="text" v-model.trim="printkey" @blur="motecreat" placeholder="请输入商标名称">
                </div>
                <div class="detail-div2-img">
                  <span>商标图样</span>
                  <input value="1" v-model="picked2" name="type2" type="radio"><span>自动生成</span>
                  <input value="2" v-model="picked2" name="type2" type="radio"><span>手动上传</span>
                </div>
                <div class="detail-div2-imgshow" v-if="picked2 == '1'">
                  <div>
                    <img :src="this.pic1" alt="">
                  </div>
                  <div>
                    <p>如：</p><img :src="require('/static/images/zhiyi.png')" alt="">
                    <p>指仅用文字构成的商标，包括中国汉字和少数名族字、外国文字和阿拉伯数字或以各种不同字组合的商标</p>
                  </div>
                </div>
                <div class="detail-div2-uploadimgshow" v-if="picked2 == '2'">
                  <div>
                    <el-upload
                      accept="image/*"
                      :http-request="upload"
                      :show-file-list="false"
                      action="">
                      <img class="idimg" :src="pic2?pic2:require('/static/images/please2.png')" alt="">
                    </el-upload>
                    <div class="remark1">注：黑白注册，注册后可以更换商标颜色使用，上传的图样必须是黑白颜色；指定颜色注册，注册后只能按照上传图样的颜色使用</div>
                  </div>

                  <div class="detail-div2-uploadimgshow-div">
                    <input value="1" v-model="picked3" name="type3" type="radio"><span>原图</span>
                    <input value="2" v-model="picked3" name="type3" type="radio"><span>黑白</span>
                  </div>
                </div>
                <div class="detail-picksize" v-if="picked2 == '1'">
                  <span>商标字体</span>
                  <el-select v-model="value" @change="getimg">
                    <!-- <el-option  value="选择字体"></el-option> -->
                    <el-option
                      v-for="item in fontoptions"
                      :key="item"
                      :label="item"
                      :value="item">
                    </el-option>
                  </el-select>
                </div>
              </div>
              <div class="detail-tuxing" v-if="picked1 == '2'">
                <span>商标图样</span>
                <div>
                  <div>
                    <el-upload
                      accept="image/*"
                      :http-request="upload2"
                      :show-file-list="false"
                      action="">
                      <img class="idimg" :src="pic3?pic3:require('/static/images/please2.png')" alt="">
                    </el-upload>
                    <div>
                      <div class="recome-tip">
                        <p>如：</p><img :src="require('/static/images/recomelogo1.png')" alt="">
                        <p>指仅用图形构成的商标，不包含任何文字</p>
                      </div>
                      <div class="remark1">注：黑白注册，注册后可以更换商标颜色使用，上传的图样必须是黑白颜色；指定颜色注册，注册后只能按照上传图样的颜色使用</div>
                    </div>
                  </div>
                  <div class="detail-tuxing-div">
                    <input value="1" v-model="picked4" name="type4" type="radio"><span>原图</span>
                    <input value="2" v-model="picked4" name="type4" type="radio"><span>黑白</span>
                  </div>
                </div>
              </div>
              <div class="detail-tuwen" v-if="picked1 == '3'">
                <div class="detail-div2-writename">
                  <span>商标名称</span>
                  <input type="text" v-model.trim="printkey2" placeholder="请输入商标名称">
                </div>
                <div class="detail-tuwendetail">
                  <span>商标图样</span>
                  <div>
                    <div>
                      <el-upload
                        accept="image/*"
                        :http-request="upload3"
                        :show-file-list="false"
                        action="">
                        <img class="idimg" :src="pic4?pic4:require('/static/images/please2.png')" alt="">
                      </el-upload>
                      <div>
                        <div class="recome-tip">
                          <p>如：</p><img :src="require('/static/images/recomelogo2.jpg')" alt="">
                          <p>指由文字、图形等两种或两种以上元素结合构成的商标</p>
                        </div>
                        <div class="remark1">注：黑白注册，注册后可以更换商标颜色使用，上传的图样必须是黑白颜色；指定颜色注册，注册后只能按照上传图样的颜色使用</div>
                      </div>
                    </div>

                    <div class="detail-tuwendetail-div">
                      <input value="1" v-model="picked5" name="type5" type="radio"><span>原图</span>
                      <input value="2" v-model="picked5" name="type5" type="radio"><span>黑白</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="recome-next1" @click="namenext">下一步</div>
            </div>
          </div>
        </div>
        <div class="recome-type" v-show="typeshow">
          <div class="recome-type-title">请选择你添加商标类别的方式</div>
          <div class="recome-type-chosetype">
            <div>
              <div :class="changetype">
                <div class="chosetype1" @click="picktype('guild')">AI智能选择</div>
                <div class="chosetype3" @click="picktype('chose')">自主类别选择</div>
                <div class="chosetype4" @click="picktype('all')">全类保护</div>
                <div v-show="historyshow" class="chosetype2" @click="picktype('history')">历史类别</div>
                <div class="chosetype5" @click="picktype('peer')">查询同行</div>
              </div>
              <div class="recome-next1" @click="typenext">下一步</div>
            </div>
          </div>
        </div>
        <div class="recome-guild" v-show="pickguildshow">
          <div class="recome-guild-title">请输入你的产品或服务名称</div>
          <div class="recome-guild-pickguild">
            <div class="recome-guild-pickguild-div" v-for="(v,k) in jobArr" :key="k">
              <input type="text" placeholder="请输入你的产品或服务名称"  v-model="jobArr[k]" @input="getNew(k)" @blur="newlose"><span v-if="k==0" @click="addjob">+</span>
              <span id="minus" @click="deljob(k)" v-else>-</span>
              <div class="recome-guild-select" v-if="newIs&&newIndex == k">
                  <div class="recome-guild-select-div" :title="item" v-for="(item,index) in newData" :key="index" @click="addOne(item,k)" v-show="newData.length !== 0">
                    {{item}}
                  </div>
                  <div class="recome-guild-select-div2" v-show="newData.length == 0">暂无匹配关键词</div>
              </div>
            </div>
            <div class="recome-next1" @click="guildnext">下一步</div>
          </div>
        </div>
        <div class="recome-pickhistory" v-show="pickhistoryshow">
          <div class="recome-pickhistory-title">请选择你的历史商标类别</div>
          <div class="recome-pickhistory-list">
            <div class="recome-pickhistory-list-div1">
              <div :class="changeClass">
                <div @click="changeType('history')" class="historytrade">已申请商标</div>
                <div @click="changeType('import')" class="importtrade">收藏商标</div>
                <div class="recome-pickhistory-list-div1-search">
                  <input type="text" placeholder="请输入商标名称" v-model="searchkey">
                  <div @click="historysearch">搜索</div>
                </div>
              </div>
            </div>
<!--            收藏商标-->
            <div class="recome-pickhistory-list-div2">
              <div v-for="(v,k) in history" :key="k">
                <div>
                  <img v-if="changeClass == 'history'" :src="v.trademarkImgUrl" alt=""
                       :class="{historychange: historyisTags(v.choseId)}" @click="gethistorylittle(v.choseId,k)">
                  <img v-if="changeClass == 'import'" :src="v.trademarkImgUrl"
                       :class="{historychange: historyisTags(v.choseId)}" @click="gethistorylittle(v.choseId,k)" alt="">
                  <div>{{ v.num }}类</div>
                  <p @click="watchhistorylittle(k)">查看类别</p>
                </div>
              </div>
            </div>
            <div class="recome-next1" @click="historynext">下一步</div>
            <div class="recome-pickhistory-list-watchlittle" v-show="historylittleshow">
              <div class="recome-pickhistory-list-watchlittle-detail">
                <img :src="require('/static/images/cha.png')" alt="" @click="historycha">
                <div class="recome-pickhistory-list-watchlittle-detail-div">
                  <div class="recome-pickhistory-list-watchlittle-detail-div1">查看类别</div>
                  <div class="recome-pickhistory-list-watchlittle-detail-div2">
                    <div v-for="(item,index) in historyarr" :key="index">
                      <div class="recome-pickhistory-list-watchlittle-detail-div2-onecate">
                        <span>{{ item.text }}</span><span>{{ item.name }}</span></div>
                      <div v-for="(v,k) in item.second" :key="k"
                           class="recome-pickhistory-list-watchlittle-detail-div2-twocate">
                        <div>{{ v.num }}{{ v.name }}</div>
                        <span v-for="(m,n) in v.three" :key="n">{{ m.name }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="recome-peer" v-show="peerShow">
          <div class="recome-peer-title">请输入同行的企业名称或商标名称</div>
          <div class="recome-peer-list">
            <div class="recome-peer-searh">
              <el-select v-model="peerType" class="recome-peer-select">
                <el-option
                v-for="item in peerSelect"
                :key="item.peerType"
                :label="item.name"
                :value="item.peerType">
                </el-option>
              </el-select>
              <input type="text" placeholder="请输入同行的企业名称或商标名称" v-model="peerWord"><span @click="peerSearch()">搜索</span>
            </div>
            <div class="recome-peer-detail">
              <div v-for="(v,k) in peerData" :key="k">
                <div class="recome-peer-detail-div">
                  <div>
                    <img :src="v.trademarkImgUrl" :class="{peerchange: peerisTags(v.choseId)}"
                         @click="getpeerlittle(v.choseId,k)" alt="">
                    <span>{{ v.trandemarkStatus }}</span>
                  </div>
                  <div :title="v.comName">{{ v.comName }}</div>
                  <div>{{ v.oneCate }}类 {{ v.oneCateName }}</div>
                  <p @click="watchpeerlittle(k)">查看类别</p>
                </div>
              </div>
            </div>
            <div class="recome-next1" @click="peerNext">下一步</div>
          </div>
          <div class="recome-pickhistory-list-watchlittle" v-show="peerlittleshow">
            <div class="recome-pickhistory-list-watchlittle-detail">
              <img :src="require('/static/images/cha.png')" alt="" @click="peercha">
              <div class="recome-pickhistory-list-watchlittle-detail-div">
                <div class="recome-peer-list-watchlittle-detail-div1">{{ peertrademarkName }}</div>
                <div class="recome-pickhistory-list-watchlittle-detail-div2">
                  <div v-for="(item,index) in peerArr" :key="index">
                    <div class="recome-pickhistory-list-watchlittle-detail-div2-onecate">
                      <span>{{ item.oneCate }}类</span><span>{{ item.oneCateName }}</span></div>
                    <div v-for="(v,k) in item.twoCateList" :key="k"
                         class="recome-pickhistory-list-watchlittle-detail-div2-twocate">
                      <div>{{ v.twoCate }} {{ v.twoCateName }}</div>
                      <span v-for="(m,n) in v.threeList" :key="n">{{ m.threeCateName }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="recome-recomed" v-show="recomedshow">
          <div class="recomed1">请选择你要注册的类别</div>
          <div class="recomed2">
            <div class="recomed2-bigdiv">
              <div class="recomed2-bigdiv-left">
                <div class="recomed2-bigdiv-left-search">
                  <input type="text" placeholder="请输入产品或服务名称" v-model="searchkeyword" @keyup.enter="regsearch">
                  <img :src="require('/static/images/orderSearch.png')" alt="">
                </div>
                <div class="recomed2-bigdiv-left-three-link">
                  <div class="three-link" v-for="(v,k) in threelink">
                    <div class="link-div1" @click="stretch1(v.categoryCode)">
                      <i :class="secondshow==isTags(v.categoryCode)? 'el-icon-caret-bottom':'el-icon-caret-right'"></i>
                      第{{ v.categoryCode }}类 {{ v.categoryName }}
                    </div>
                    <div class="link-div2" v-for="(item,index) in v.categorysList" v-show="isTags(v.categoryCode)">
                      <div @click="stretch2(item.categoryCode)"><i
                        :class="thirdshow==isTags2(item.categoryCode)? 'el-icon-caret-bottom':'el-icon-caret-right'"></i>
                        {{ item.categoryCode }}{{ item.categoryName }}
                      </div>
                      <div class="link-div3" v-show="isTags2(item.categoryCode)" v-for="(m,n) in item.categorysList"
                           @click="addcate(v.categoryCode,v.categoryName,item.categoryCode,item.categoryName,item.score,m.code,m.categoryName,item.rate)">
                        <span :class="{setActive: isexit(m.code)}"> {{ m.categoryName }}</span></div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="recomed2-bigdiv-right">
                <div class="recomed2-bigdiv-right-top">
                  <div>推荐注册的商品项目</div>
                  <div @click="showprice">费用标准说明</div>
                  <div v-show="priceshow">
                    <p>星级与下证率说明：</p>
                    <p>星级根据国家商标局审查标准预测，商标星级越高，则注册成功率越高。下证率根据平台已有订单数据统计得出，为本平台上该星级商标的下证成功率</p>
                    <p>四星商标的下证率在80%以上，建议注册<br>三星商标的下证率约为70%~80%，建议注册<br>二星商标的下证率约为50%~60%，可以尝试注册<br>一星商标的下证率在0%~10%，不建议注册
                    </p>
                    <p>费用说明：</p>
                    <p>1、商标按大类计件，例如申请同一名称的两个大类，计为两件商标；</p>
                    <p>2、每件商标的基础价格包含同个大类中的10个小组，超出的小组需额外收取{{littleprice}}元/组的官费；</p>
                    <p>3、每件商标的总计金额为：官费+服务费，其中官费包含基础价格和超出的小组价格；</p>
                    <p>*本服务最终解释权归平台所有</p>
                  </div>
                </div>
                <div class="recomed2-bigdiv-right-showtype">
                  <div class="recomed2-bigdiv-right-showtype-cirl" v-for="(item,index) in suggest">
                    <div class="div1">
                      <input type="checkbox" @click="selectfirst(item.isSelect1,index)" v-bind:checked="item.isSelect1">
                      <span>第{{ item.num }}类 {{ item.name }}</span>
                      <i class="el-icon-delete" @click="delfirst(index)"></i>
                      <span v-if="item.primary == 1">主要推荐类别</span>
                      <span v-if="item.primary == 0">相关推荐类别</span>

                      <span class="div1-span1" v-if="item.score != 0">下证率</span><span class="div1-span2"
                                                                                      v-if="item.score != 0">{{ item.rate }}%</span>
                      <div v-if="item.score != 0">
                        <el-rate
                          v-model="item.score"
                          disabled
                          score-template="{value}">
                        </el-rate>
                      </div>

                    </div>
                    <div class="container" v-for="(v,k) in item.sec">
                      <div class="div2">
                        <div>
                          <input type="checkbox" v-bind:checked="v.isSelect2"
                                 @click="selectsecond(v.isSelect2,index,k)">
                          <div :title="v.name">{{ v.num }} {{ v.name }}</div>
                          <i class="el-icon-delete second" @click="delsecond(index,k)"></i>
                        </div>
                        <div v-if="v.score != 0">
                          <span class="div1-span1">下证率</span>
                          <span class="div1-span2">{{ v.randomNum }}%</span>
                          <el-rate
                            v-model="v.score"
                            disabled
                            score-template="{value}">
                          </el-rate>
                          <div v-if="v.score<=3" @click="like(item.num,v.num)">查看近似商标</div>
                        </div>
                      </div>
                      <div class="div3">
                        <div v-for="(m,n) in v.third" :class="{self1:isSeft(m.code),includeClass:isinclude(m.name)}" >
                          <div :class="{self2:isSeft(m.code),includeClass:isinclude(m.name)}">{{ m.name }}</div>
                          <div :class="{self3:isSeft(m.code),includeClass:isinclude(m.name)}" @click="del(item.num,v.num,index,n,m.code,k)">x</div>
                        </div>
                        <div id="clear"></div>
                        <!-- <div class="add" @click="zu(item.num,v.num)">添加</div> -->
                      </div>
                      <div style="clear:both;"></div>
                    </div>
                    <div class="type-box-right-showtype-choose">
                      <div class="type-box-right-showtype-choose-div1">
                        <div>已选<span>{{ item.chose }}</span>项 <span>(超过10项，每项加收{{ littleprice }}元)</span></div>
                        <div>
                          <span>总计金额：</span>
                          <span>￥{{ item.price }} </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="type-box-right-showtype-addcart">
                  <div>
                    <input type="checkbox" :disabled='suggest.length == 0' :checked='allcheck' @click="choseall"><span>全选</span>
                  </div>
                  <div id="type-box-right-showtype-addcart-del">
                    <div @click="deldata">
                      <i class="el-icon-delete"></i><span>删除</span>
                    </div>
                    <div @click="collect" v-if="selectCollectM">
                      <i class="el-icon-star-off"></i><span>收藏</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="type-box-total">
              <div @click="rightbuy" v-show="buttonshow">立即购买</div>
              <div @click="addcart">加入购物车</div>
              <div :="money">
                <span>已选{{ totalnum }}项，应付总额：</span>
                <span>￥{{ totalprice }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <el-dialog
        title="提示"
        :visible.sync="dialogVisible"
        width="30%">
        <span>确定要删除该类别吗？</span>
        <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="confireQuit">确 定</el-button>
            </span>
      </el-dialog>
    </div>
    <Foot></Foot>
    <div class="table" v-show="maskshow">
      <el-table
        v-loading="loading" class="table">
      </el-table>
    </div>
  </div>
</template>

<script>
import Top from '../ui/top.vue';
import Foot from '../ui/foot.vue';
import Fix from '../ui/fix.vue';
import client from '@/oss.js';
import moment from 'moment';

const delay = (function () {
  let timer = 0;
  return function (callback, ms) {
    clearTimeout(timer);
    timer = setTimeout(callback, ms);
  };
})();
export default {
  name: 'recome',
  data() {
    return {
      nameshow: true,
      picked1: "1",
      picked2: "1",
      picked3: "1",
      picked4: '1',
      picked5: '1',
      pic1: '',
      pic2: '',
      pic3: '',
      pic4: '',
      value: '宋体',
      printkey: '',
      printkey2: '',
      fontoptions: [],
      typeshow: false,
      type1: '',
      type2: '',
      type3: '',
      type4: '',
      type5: '',
      type6: '',
      loading: true,
      maskshow: false,
      // 总的商标名称
      keyword: '',
      // 总的图片路径
      srcurl: '',
      // 判断历史类别显示隐藏
      pickhistoryshow: false,
      historyshow: Boolean,
      changetype: 'guild',
      // 输入的行业名称
      // 小行业
      pickguildshow: false,
      key: '',
      jobArr: [''],

      // 历史类别
      history: [],
      changeClass: 'history',
      searchkey: '',
      // 历史类别大类
      historylittleshow: false,
      // historycate:'',
      // historyName:'',
      historyarr: [],
      historychange: '',
      historyid: [],
      recomedshow: false,
      // 价格说明的显示隐藏
      priceshow: false,
      //右边推荐的数据
      suggest: [],
      options: [],
      // 左边第一大类的数据
      threelink: [],
      num: 0,
      // 全选的属性
      allcheck: false,

      //  保存所有三级id判断左边三级字体变红
      array: [],
      // 左边45大类的搜索关键词
      searchkeyword: '',
      //   搜索出来的数据数组
      searcharr: [],
      //    保存搜索出来所有一级和二级的id判断二级和三级的显示隐藏
      firstid: [],
      secondid: [],
      //    判断箭头是否向下
      secondshow: true,
      thirdshow: true,

      type: '',
      //   删除提示
      dialogVisible: false,

      firstnum: 0,
      secondnum: 0,

      // 判断立即购买显示隐藏
      buttonshow: true,

      // 代理人小商品价格
      littleprice: '',

      // 判断是否是自主注册
      select_type: '',
      // 总的价格
      totalprice: 0,
      totalnum: 0,

      // 基础价格
      basePrice: '',
      // 保存获取回来的推荐的code来获取全部的星级
      code: '',
      // 存储星级
      star: [],
      radom: {},
      // Ai商标注册的code
      goodsCode: '',
      // 下单人
      person: '',
      personshow: false,
      personArr: [],
      // 总的是黑白还是原图
      trademarkColorType: '',
      personInfo: {},
      selectCollectM: true,
      // 查询同行
      peerShow: false,
      peerlittleshow: false,
      peerWord: '',
      peerList: [],
      peerData: [],
      peerArr: [],
      peerid: [],
      peertrademarkName: '',
      peerchange: '',
      // 查询同行选择
      peerType:'2',
      peerSelect:[{
        peerType:"2",
        name:'商标名称'
      },{
        peerType:"3",
        name:'企业名称'
      }],
      // 输入商品名称查询
      newIndex:'',
      newIs:false,
      newOne:'',
      newData:[],
      // 自己增加的小商品改变样式
      selfAdd:[],
      // 判断是否和行业一样
      inclu:[]
    }
  },
  components: {
    Top,
    Foot,
    Fix
  },
  computed: {
    money() {
      this.totalprice = 0;
      this.totalnum = 0;
      for (var i = 0; i < this.suggest.length; i++) {
        this.totalprice += this.suggest[i].price;
        this.totalnum += this.suggest[i].chose;
      }
    }
  },
  watch: {
    "picked1"(newValue) {
      if (newValue == '1') {
        this.typeshow = false;
        this.pickguildshow = false;
        this.pickhistoryshow = false;
        this.recomedshow = false;
        this.peerShow = false;
      } else if (newValue == '2') {
        this.typeshow = false;
        this.pickguildshow = false;
        this.pickhistoryshow = false;
        this.recomedshow = false;
        this.peerShow = false;
      } else {
        this.typeshow = false;
        this.pickguildshow = false;
        this.pickhistoryshow = false;
        this.recomedshow = false;
        this.peerShow = false;
      }
    },
    "picked2"(newValue) {
      if (newValue == '宋体') {
        this.motecreat();
      }
    },
    "suggest"(newValue) {
      if (newValue.length == 0) {
        this.allcheck = false;
      }
    },
    "picked3"(newValue) {
      if (newValue == '1') {
        if (this.pic2 != '') {
          this.pic2 = this.type1;
        }
      } else {
        if (this.pic2 != '') {
          this.pic2 = this.type2;
        }
      }
    },
    "picked4"(newValue) {
      if (newValue == '1') {
        if (this.pic3 != '') {
          this.pic3 = this.type3;
        }
      } else {
        if (this.pic3 != '') {
          this.pic3 = this.type4;
        }
      }
    },
    "picked5"(newValue) {
      if (newValue == '1') {
        if (this.pic4 != '') {
          this.pic4 = this.type5;
        }
      } else {
        if (this.pic4 != '') {
          this.pic4 = this.type6;
        }
      }
    },
    "changetype"(newValue) {
      if (newValue == 'guild' || newValue == 'all') {
        this.pickguildshow = true;
        this.pickhistoryshow = false;
        this.recomedshow = false;
        this.peerShow = false;
      } else if (newValue == 'history') {
        this.pickguildshow = false;
        this.pickhistoryshow = true;
        this.recomedshow = false;
        this.peerShow = false;
      } else if (newValue == 'peer') {
        this.pickguildshow = false;
        this.pickhistoryshow = false;
        this.recomedshow = false;
        this.peerShow = false;
      } else {
        this.pickguildshow = false;
        this.pickhistoryshow = false;
        this.recomedshow = false;
        this.peerShow = false;
      }
    },
    'newOne'(newValue) {
      // console.log(this.jobArr)
      if (newValue == "") {
        this.newIs = false;
      } else {
        this.newIs = true;
        // console.log(newValue)
        for (var i = 0; i < this.newData.length; i++) {
          if (this.newData[i] == newValue) {
            this.newIs = false;
          }
        }
        delay(() => {
          this.fetchData();
        }, 400);
      }
    }
  },
  methods: {
    tip(type, msg) {
      return this.$message({
        type: type,
        message: msg,
        showClose: true,
        duration: 3000
      })
    },
    handleScroll(){
      setTimeout(function(){
          $('html, body').animate({scrollTop: 10000}, 1000)
        },200)
      },
    async fetchData(val) {
      this.$http.post(this.GLOBAL.new_url + '/trademark/service/es/trademark/regiest/search/completion/suggest', {
         keyword: this.jobArr[this.newIndex],
         page:1,
         size:100
      }, {
        headers: {
          AuthToken: this.$cookie.getCookie('token'),
          'X-Request-Origin': 'xcx'
        }
      }).then(res => {
        // console.log(res);
        if (res.data.code == "200") {
          this.newData = res.data.data.wvNameList;
        }
      })
    },
    addname(val) {
      this.person = val;
      let reg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
      if (!reg.test(this.person)) {
        this.tip('error', '手机号不正确')
      }
      setTimeout(() => {
        this.personshow = false;
      }, 500);
    },
    async upload(e) {
      let fileName = moment().format('YYYYMMDDmmss') + e.file.name;
      try {
        let result = await client.put('/pc/' + fileName, e.file);
        console.log(result)
        if (result.url != '') {
          this.$http.post(this.GLOBAL.new_url + '/trademark/service/ai/cutBlackImage', {
            files: result.url
          }, {
            headers: {
              AuthToken: this.$cookie.getCookie('token'),
              'X-Request-Origin': 'xcx'
            }
          }).then(res => {
            // console.log(res)
            if (res.data.code == "200") {
              this.type1 = result.url;
              this.type2 = res.data.data.url;
              if (this.picked3 == '2') {
                this.pic2 = res.data.data.url;
              } else {
                this.pic2 = result.url;
              }
            }
          })
        }
      } catch (e) {
        console.log(e);
      }
    },
    async upload2(e) {
      let fileName = moment().format('YYYYMMDDmmss') + e.file.name;
      try {
        let result = await client.put('/pc/' + fileName, e.file);
        if (result.url != '') {
          this.$http.post(this.GLOBAL.new_url + '/trademark/service/ai/cutBlackImage', {
            files: result.url
          }, {
            headers: {
              AuthToken: this.$cookie.getCookie('token'),
              'X-Request-Origin': 'xcx'
            }
          }).then(res => {
            // console.log(res)
            if (res.data.code == "200") {
              this.type3 = result.url;
              this.type4 = res.data.data.url;
              if (this.picked4 == '2') {
                this.pic3 = res.data.data.url;
              } else {
                this.pic3 = result.url;
              }
            }
          })
        }
      } catch (e) {
        console.log(e);
      }
    },
    async upload3(e) {
      let fileName = moment().format('YYYYMMDDmmss') + e.file.name;
      try {
        let result = await client.put('/pc/' + fileName, e.file);
        if (result.url != '') {
          this.$http.post(this.GLOBAL.new_url + '/trademark/service/ai/cutBlackImage', {
            files: result.url
          }, {
            headers: {
              AuthToken: this.$cookie.getCookie('token'),
              'X-Request-Origin': 'xcx'
            }
          }).then(res => {
            // console.log(res)
            if (res.data.code == "200") {
              this.type5 = result.url;
              this.type6 = res.data.data.url;
              if (this.picked5 == '2') {
                this.pic4 = res.data.data.url;
              } else {
                this.pic4 = result.url;
              }
            }
          })
        }
      } catch (e) {
        console.log(e);
      }
    },
    namenext() {
      let reg = /^[0-9]*$/;
      let reg2 = /[`~!@#$%^&*()_\-+=<>?:"{}|,.\/;'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘’，。、]/im;
      if (this.picked1 == "1") {
        if (this.printkey == '') {
          this.tip('error', "请输入商标名称")
          return false
        } else if (reg.test(this.printkey)) {
          this.tip('error', "请输入汉字或字母")
          return false
        } else if (reg2.test(this.printkey)) {
          this.tip('error', "请输入正确的商标名称")
          return false
        } else if (this.picked2 == "1") {
          if (this.pic1 == '') {
            this.tip('error', "请生成商标图片")
            return false
          } else {
            this.maskshow = true;
            this.$http.post(this.GLOBAL.new_url + '/trademark/service/ai/forbid', {
              text: this.printkey
            }, {
              headers: {
                AuthToken: this.$cookie.getCookie('token'),
                'X-Request-Origin': 'xcx'
              }
            }).then(res => {
              // console.log(res)
              if (res.data.data.forbid == "True") {
                this.tip('error', res.data.data.reason);
                this.maskshow = false;
                this.keyword = this.printkey
                this.srcurl = this.pic1;
                this.trademarkColorType = 1
                return false;

              } else {
                this.maskshow = false;
                this.typeshow = true;
                this.keyword = this.printkey
                this.srcurl = this.pic1;
                this.trademarkColorType = 1
                this.handleScroll()
              }
            })
          }
        } else if (this.picked2 == "2") {
          if (this.pic2 == '') {
            this.tip('error', "请上传商标图片")
            return false
          } else {
            this.maskshow = true;
            this.$http.post(this.GLOBAL.new_url + '/trademark/service/ai/forbid', {
              text: this.printkey
            }, {
              headers: {
                AuthToken: this.$cookie.getCookie('token'),
                'X-Request-Origin': 'xcx'
              }
            }).then(res => {
              if (res.data.data.forbid == "True") {
                this.tip('error', res.data.data.reason);
                this.maskshow = false;
                this.keyword = this.printkey
                this.srcurl = this.pic2
                if (this.picked3 == 1) {
                  this.trademarkColorType = 2
                } else {
                  this.trademarkColorType = 1
                }
                return false;

              } else {
                this.maskshow = false;
                this.typeshow = true;
                this.handleScroll()
                this.keyword = this.printkey
                this.srcurl = this.pic2
                if (this.picked3 == 1) {
                  this.trademarkColorType = 2
                } else {
                  this.trademarkColorType = 1
                }

              }
            })
          }

        }
      } else if (this.picked1 == "2") {
        if (this.pic3 == '') {
          this.tip('error', "请上传商标图片")
          return false
        } else {
          this.keyword = '';
          this.srcurl = this.pic3;
          this.typeshow = true;
          this.handleScroll()
          if (this.picked4 == 1) {
            this.trademarkColorType = 2
          } else {
            this.trademarkColorType = 1
          }
        }
      } else {
        if(this.printkey2 == ''){
          this.tip('error', "请输入商标名称")
          return false
        }else if (reg.test(this.printkey2) && this.printkey2 != '') {
          this.tip('error', "请输入汉字或字母")
          return false
        } else if (reg2.test(this.printkey2) && this.printkey2 != '') {
          this.tip('error', "请输入汉字或字母")
          return false
        } else if (this.pic4 == '') {
          this.tip('error', "请上传商标图片")
          return false
        } else {
          this.keyword = this.printkey2;
          this.srcurl = this.pic4;
          this.typeshow = true;
          this.handleScroll()
          if (this.picked5 == 1) {
            this.trademarkColorType = 2
          } else {
            this.trademarkColorType = 1
          }
        }
      }
    
      
 
      
    
    },
    typenext() {
      if (this.suggest.length != 0) {
        this.namenext()
      }
      if (this.changetype == 'guild' || this.changetype == 'all') {
        this.pickguildshow = true;
        this.pickhistoryshow = false;
        this.recomedshow = false;
        this.peerShow = false;
        this.handleScroll()
      } else if (this.changetype == 'history') {
        this.pickguildshow = false;
        this.pickhistoryshow = true;
        this.recomedshow = false;
        this.peerShow = false;
        this.handleScroll()
      } else if (this.changetype == 'peer') {
        this.pickguildshow = false;
        this.pickhistoryshow = false;
        this.recomedshow = false;
        this.peerShow = true;
        this.suggest = [];
        this.peerData = [];
        this.peerid = [];
        this.peerWord = '';
        this.handleScroll()
      } else {
        this.pickguildshow = false;
        this.pickhistoryshow = false;
        this.peerShow = false
        this.getdata3();
        this.handleScroll()
      }
    },
    getfont() {
      this.$http.post(this.GLOBAL.new_url + '/trademark/service/getTypeface', {}, {
        headers: {
          AuthToken: this.$cookie.getCookie('token'),
          'X-Request-Origin': 'xcx'
        }
      }).then(res => {
        // console.log(res)
        if (res.data.code == "200") {
          this.fontoptions = res.data.data;
        }

      })
    },
    getimg() {
      this.motecreat();
    },
    motecreat() {
      if (this.printkey == '') {
        return false
      } else {
        this.$http.post(this.GLOBAL.new_url + '/trademark/service/putTextToPic', {
          text: this.printkey,
          typeface: this.value,
          width: 400,
          height: 400,
          fontSize: 50
        }, {
          headers: {
            AuthToken: this.$cookie.getCookie('token'),
            'X-Request-Origin': 'xcx'
          }
        }).then(res => {
          // console.log(res)
          if (res.data.code == "200") {
            this.pic1 = res.data.data;
          }
        })
      }
    },
    picktype(val) {
      this.changetype = val
      this.array = []
      this.inclu = []
    },
    addjob() {
      if (this.jobArr.length != 5) {
        this.jobArr.push('');
      }
    },
    deljob(val) {
      this.jobArr.splice(val, 1)
    },
    getNew(k){
        this.newIndex = k;
        // this.newIs = true;
        this.newOne = this.jobArr[k]
    },
    newlose(){
      setTimeout(() => {
        this.newIs = false;
      }, 500);
    },
    addOne(val,k){
      this.jobArr[k] = val;
      this.newlose()
    },
    guildnext() {
      if (this.suggest.length != 0) {
        this.namenext()
      }

      this.key = this.jobArr.join(',')
      if (this.key.charAt(this.key.length - 1) == ',') {
        this.key = this.key.substring(0, this.key.length - 1);
      }
      if (this.key == '') {
        this.tip('error', '请输入您的产品名称或者服务行业')
        return false;
      } else {

        // this.recomedshow = true;
        this.getdata3();
        this.handleScroll()
      }
    },
    changeType(val) {
      this.changeClass = val;
      this.historyid = [];
      this.searchkey = '';
      this.gethistorydata();
    },
    historysearch() {
      this.historyid = [];
      this.gethistorydata();
    },
    gethistorydata() {
      this.history = [];
      var type;
      if (this.changeClass == 'history') {
        type = 1;
      } else {
        type = 2;
      }
      // this.maskshow = true;
      this.$http.post(this.GLOBAL.new_url + '/trademarkhiscoll/webapi/list/pc', {
        trademarkHisColType: type,
        keyWord: this.searchkey
      }, {
        headers: {
          AuthToken: this.$cookie.getCookie('token'),
          'X-Request-Origin': 'xcx'
        }
      }).then(res => {
        // console.log(res)
        if (res.data.code == 200) {
          this.history = [];
          this.maskshow = false;
          var historyData = [];
          historyData = res.data.data;
          historyData.map((x, y) => {
            x.trademarkBody = JSON.parse(x.trademarkBody);
            var _this = this;
            x.trademarkBody.map((a, b) => {
              _this.$set(a, 'trademarkImgUrl', x.trademarkImgUrl);
              _this.$set(a, 'isSelect1', false);
              _this.$set(a, 'choseId', b);
            })
          })
          // console.log( historyData)
          for (var i = 0; i < historyData.length; i++) {
            for (var j = 0; j < historyData[i].trademarkBody.length; j++) {
              this.history.push(historyData[i].trademarkBody[j]);
            }
          }
          console.log(this.history);
          var _this = this;
          this.history.map((a, b) => {
            _this.$set(a, 'choseId', b);
          })
          // console.log(this.history)

        }
      })
    },
    watchhistorylittle(val1) {
      this.historylittleshow = true;
      this.historyarr = [];
      this.historyarr.push(this.history[val1]);
      console.log(this.historyarr);
      // console.log(this.historyarr)
    },
    historycha() {
      this.historylittleshow = false;
    },
    historyisTags(tagId) {
      if (this.historyid.includes(tagId)) {
        return true;
      } else {
        return false;
      }
    },
    gethistorylittle(val, val2) {
      this.history[val2].isSelect1 = !this.history[val2].isSelect1;
      //   console.log(this.history)
      if (this.historyid.length == 0) {
        this.historyid.push(val)
      } else {
        if (this.historyid.includes(val)) {
          for (var i = 0; i < this.historyid.length; i++) {
            if (val == this.historyid[i]) {
              this.$delete(this.historyid, i);
            }
          }
        } else {
          this.historyid.push(val)
        }
      }
    },
    historynext() {
      this.suggest = [];
      if (this.historyid.length == 0) {
        this.tip('error', '请选择您要选择的历史类别')
      } else {
        let arr = JSON.parse(JSON.stringify(this.history));
        let newArr = [];
        for (let i = 0; i < arr.length; i++) {
          if (arr[i].isSelect1 === true) {
            newArr.push(arr[i]);
          }
        }
        console.log(this.history);
        this.suggest = this.dataFilter(newArr).map((x, y) => {
          return {
            num: x.num,
            name: x.name,
            isSelect1: x.isSelect1,
            chose: 0,
            price: 0,
            sec: x.second.map((a, b) => {
              return a = {
                num: a.num,
                code: a.id,
                name: a.name,
                isSelect2: true,
                third: a.three.map((i, o) => {
                  return i = {
                    code: i.id,
                    num: i.num,
                    name: i.name,

                  };
                })
              };
            })
          };
        });
        console.log(this.suggest)
        // 把所有三级的id保存在数组判断右边变红
        for (var i = 0; i < this.suggest.length; i++) {
          for (var j = 0; j < this.suggest[i].sec.length; j++) {
            for (var m = 0; m < this.suggest[i].sec[j].third.length; m++) {
              this.array.push(String(this.suggest[i].sec[j].third[m].code))
            }
          }
        }
        this.recomedshow = true

        this.getdata3()
        this.getdata2()
        this.handleScroll()
      }
    },
    dataFilter(data) {
      let arr = []
      data.map(i => {
        let item = JSON.parse(JSON.stringify(i));
        let findIndex = arr.findIndex(_i => {
          if(_i.num || i.num) return _i.num === i.num
          if(_i.id || i.id) return _i.id === i.id
        });
        if (findIndex === -1) { // 没有重复
          arr.push(item)
        } else {
          let childKey
          if ('second' in i) childKey = 'second';
          if ('three' in i) childKey = 'three';
          if (!childKey) return false; //没有子级
          let child = [...arr[findIndex][childKey], ...i[childKey]];
          arr[findIndex][childKey] = this.dataFilter(child)
        }
      })
      return arr
    },
    // 同行
    peercha() {
      this.peerlittleshow = false
    },
    watchpeerlittle(k) {
      this.peerlittleshow = true;
      this.peerArr = [];
      this.peerArr.push(this.peerData[k])
      // console.log(this.peerArr)
      this.peertrademarkName = this.peerArr[0].trademarkName;
    },
    peerSearch() {
      this.peerid = [];
      // this.namenext()
      this.getpeerData()
    },
    getpeerData() {
      if (this.peerWord != '') {
        this.maskshow = true
        this.$http.post(this.GLOBAL.new_url + '/trademark/service/ai/getPeer', {
          userCode: this.$cookie.getCookie('u_id'),
          text: this.peerWord,
          queryType: this.peerType,
          index: 1,
          size: 100
        }, {
          headers: {
            AuthToken: this.$cookie.getCookie('token'),
            'X-Request-Origin': 'xcx'
          }
        }).then(res => {
          // console.log(res)
          if (res.data.code == "200") {
            this.peerList = res.data.data.list;

            if (this.peerList.length != 0) {
              if (this.keyword != '') {
                // https://aipro.guolaoai.com/private/rate
                this.$http.post('https://aipro.guolaoai.com/newRate/rate', {
                  text: this.keyword
                }).then(res => {
                  // console.log(res)
                  if (res.data.code == '200') {
                    for (var i in res.data.data) {
                      this.star.push({code: i, score: Number(res.data.data[i])})
                    }
                    for (var i = 0; i < this.star.length; i++) {
                      if (Number(this.star[i].code < 1000)) {
                        this.star[i].code = '0' + this.star[i].code
                      }
                    }
                    // console.log(this.star)
                    this.$http.post(this.GLOBAL.new_url + '/trademark/service/ai/queryRandomIssuingRate', {}, {
                      headers: {
                        AuthToken: this.$cookie.getCookie('token'),
                        'X-Request-Origin': 'xcx'
                      }
                    }).then(res => {
                      if (res.data.code == '200') {
                        this.radom = res.data.data;
                        var _this = this;
                        for (var i = 0; i < this.peerList.length; i++) {
                          for (var j = 0; j < this.peerList[i].TrademarkList.length; j++) {
                            _this.$set(this.peerList[i].TrademarkList[j], 'isSelect1', false);
                            for (var m = 0; m < this.peerList[i].TrademarkList[j].twoCateList.length; m++) {
                              _this.$set(this.peerList[i].TrademarkList[j].twoCateList[m], 'isSelect2', false);
                              for (var k = 0; k < this.star.length; k++) {
                                if (this.star[k].code == this.peerList[i].TrademarkList[j].twoCateList[m].twoCate) {
                                  this.peerList[i].TrademarkList[j].twoCateList[m].score = this.star[k].score
                                  this.peerList[i].TrademarkList[j].twoCateList[m].randomNum = Number(this.radom[this.star[k].score]);
                                }
                              }
                            }
                          }
                        }
                        this.maskshow = false
                      }
                    })
                  }
                }).catch((err) =>{
                this.maskshow = false;
                this.recomedshow = false;
                console.log(err);
            });

              } else {
                var _this = this;
                for (var i = 0; i < this.peerList.length; i++) {
                  for (var j = 0; j < this.peerList[i].TrademarkList.length; j++) {
                    _this.$set(this.peerList[i].TrademarkList[j], 'isSelect1', false);
                    for (var m = 0; m < this.peerList[i].TrademarkList[j].twoCateList.length; m++) {
                      _this.$set(this.peerList[i].TrademarkList[j].twoCateList[m], 'isSelect2', false);
                      this.peerList[i].TrademarkList[j].twoCateList[m].score = 0;
                      this.peerList[i].TrademarkList[j].twoCateList[m].randomNum = 0;
                    }
                  }
                }
                this.maskshow = false
              }
              this.peerList.map((x, y) => {
                var _this = this;
                x.TrademarkList.map((a, b) => {
                  _this.$set(a, 'comName', x.comName);
                  _this.$set(a, 'trademarkImgUrl', x.trademarkImgUrl);
                  _this.$set(a, 'trademarkName', x.trademarkName);
                })
              })
              // console.log(this.peerList)

              let otherArr = [];
              for (var i = 0; i < this.peerList.length; i++) {
                otherArr = otherArr.concat(this.peerList[i].TrademarkList);
              }

              for (var i = otherArr.length - 1; i >= 0; i--) {
                for (var j = otherArr[i].twoCateList.length - 1; j >= 0; j--) {
                  if (otherArr[i].twoCateList[j].threeList.length == 0) {
                    otherArr[i].twoCateList.splice(j, 1)
                    if (otherArr[i].twoCateList.length == 0) {
                      otherArr.splice(i, 1)
                    }
                  }
                }
              }
              // console.log(otherArr)
              this.peerData = otherArr;
              var _this = this;
              this.peerData.map((a, b) => {
                _this.$set(a, 'choseId', b);
              })
              console.log(this.peerData)
            } else {
              this.maskshow = false;
              this.peerData = []
              this.tip('error', '暂无数据')
            }
          } else {
            this.maskshow = false;
            this.peerData = []
          }
        })
      }
    },
    peerisTags(tagId) {
      if (this.peerid.includes(tagId)) {
        return true;
      } else {
        return false;
      }
    },
    getpeerlittle(val, val2) {
      this.peerData[val2].isSelect1 = !this.peerData[val2].isSelect1;
      //   console.log(this.history)
      if (this.peerid.length == 0) {
        this.peerid.push(val)
      } else {
        if (this.peerid.includes(val)) {
          for (var i = 0; i < this.peerid.length; i++) {
            if (val == this.peerid[i]) {
              this.$delete(this.peerid, i);
            }
          }
        } else {
          this.peerid.push(val)
        }
      }
    },
    peerNext() {
      this.suggest = [];
      if (this.peerid.length == 0) {
        this.tip('error', '请选择同行业类别')
      } else {
        let arr = JSON.parse(JSON.stringify(this.peerData))
        var newArr = [];
        for (var i = 0; i < arr.length; i++) {
          if (arr[i].isSelect1 == true) {
            newArr.push(arr[i]);
          }
        }
        console.log(arr);
        this.suggest = this.dataFilter2(newArr).map((x, y) => {
          return x = {
            num: x.oneCate,
            name: x.oneCateName,
            isSelect1: x.isSelect1,
            chose: 0,
            price: 0,
            sec: x.twoCateList.map((a, b) => {
              return a = {
                num: a.twoCate,
                code: a.code,
                name: a.twoCateName,
                randomNum: a.randomNum,
                score: a.score,
                isSelect2: true,
                third: a.threeList.map((i, o) => {
                  return i = {
                    code: i.code,
                    num: i.threeCate,
                    name: i.threeCateName,

                  };
                })
              };
            })
          };
        });
        console.log(this.suggest)
        // 把所有三级的id保存在数组判断右边变红
        for (var i = 0; i < this.suggest.length; i++) {
          for (var j = 0; j < this.suggest[i].sec.length; j++) {
            for (var m = 0; m < this.suggest[i].sec[j].third.length; m++) {
              this.array.push(String(this.suggest[i].sec[j].third[m].code))
            }
          }
        }
        this.maskshow = true;
        this.getdata2()
        this.getdata3()
        this.recomedshow = true
        this.handleScroll()
      }
    },
    dataFilter2(data) {
      let arr = []
      data.map(i => {
        let item = JSON.parse(JSON.stringify(i));
        let key ;
        if('oneCate' in item) key = 'oneCate';
        if('twoCate' in item) key = 'twoCate';
        if('threeCate' in item) key = 'threeCate';
        let findIndex = arr.findIndex(_i => {
          return _i[key] === i[key]
        });
        if (findIndex === -1) { // 没有重复
          arr.push(item)
        } else {
          let childKey
          if ('twoCateList' in i) childKey = 'twoCateList';
          if ('threeList' in i) childKey = 'threeList';
          if (!childKey) return false; //没有子级
          let child = [...arr[findIndex][childKey], ...i[childKey]];
          arr[findIndex][childKey] = this.dataFilter2(child)
        }
      })
      return arr
    },
    //   获取检索推荐的数据
    getdata1() {

      if (this.$route.query.shop_id) {
        this.suggest = [];
        this.array = [];
        this.allcheck = false;
        this.maskshow = true;
        this.keyword = this.$route.query.name;
        this.$http.post(this.GLOBAL.new_url + '/trademark/service/cart/queryCartInfo', {
          cartCode: this.$route.query.shop_id
        }, {
          headers: {
            AuthToken: this.$cookie.getCookie('token'),
            'X-Request-Origin': 'xcx'
          }
        }).then(res => {
          // console.log(res)
          if (res.data.code == "200") {
            this.suggest = res.data.data;
            var _this = this;
            this.getdata2();
            // 给推荐的一级数据里面添加属性

            this.suggest = this.suggest.map((x, y) => {
              return x = {
                num: x.collectCate,
                name: x.collectName,
                isSelect1: false,
                chose: 0,
                price: 0,
                sec: x.cartInfoVOList.map((a, b) => {
                  return a = {
                    num: a.collectCate,
                    code: a.categoryCode,
                    name: a.collectName,
                    isSelect2: false,
                    score: Number(a.successRateInfo),
                    randomNum: a.cardChangeInfo,
                    third: a.cartInfoVOList.map((i, o) => {
                      return i = {
                        code: i.categoryCode,
                        num: i.collectCate,
                        name: i.collectName,

                      };
                    })
                  };
                })
              };
            });

            // 把所有三级的id保存在数组判断右边变红
            for (var i = 0; i < this.suggest.length; i++) {
              for (var j = 0; j < this.suggest[i].sec.length; j++) {
                for (var m = 0; m < this.suggest[i].sec[j].third.length; m++) {
                  this.array.push(String(this.suggest[i].sec[j].third[m].code))
                }
              }
            }
          }
        })

      } else if (this.changetype == "history") {

        this.maskshow = true;
        if (this.changetype != 'chose' && this.keyword != '') {
          this.$http.post( 'https://aipro.guolaoai.com/newRate/rate', {
            text: this.keyword
          }).then(res => {
            // console.log(res)
            if (res.data.code == '200') {
              for (var i in res.data.data) {
                this.star.push({code: i, score: Number(res.data.data[i])})
              }
              this.$http.post(this.GLOBAL.new_url + '/trademark/service/ai/queryRandomIssuingRate', {}, {
                headers: {
                  AuthToken: this.$cookie.getCookie('token'),
                  'X-Request-Origin': 'xcx'
                }
              }).then(res => {
                // console.log(res)
                if (res.data.code == '200') {


                  this.radom = res.data.data;
                  // console.log(this.radom)
                  for (var i = 0; i < this.suggest.length; i++) {
                    for (var j = 0; j < this.suggest[i].sec.length; j++) {
                      for (var k = 0; k < this.star.length; k++) {
                        if (this.star[k].code == this.suggest[i].sec[j].num) {
                          this.suggest[i].sec[j].score = this.star[k].score
                          this.suggest[i].sec[j].randomNum = Number(this.radom[this.star[k].score])
                        }
                      }
                      // if(Number(this.suggest[i].sec[j].num<1000)){
                      //     this.suggest[i].sec[j].num = '0'+this.suggest[i].sec[j].num;
                      // }
                    }
                  }
                  this.choseall()
                }
              })
            }
          }).catch((err) =>{
                this.maskshow = false
                this.recomedshow = false;
                console.log(err);
            });
        } else {

          for (var i = 0; i < this.suggest.length; i++) {
            for (var j = 0; j < this.suggest[i].sec.length; j++) {
              this.suggest[i].sec[j].score = 0;
              this.suggest[i].sec[j].rate = 0;

            }
          }
          this.choseall()
        }

        console.log(this.suggest)
      } else if (this.changetype == "chose") {
        this.suggest = [];
        this.array = [];
        this.allcheck = false;
        this.maskshow = true;
        this.recomedshow = true;
        this.getdata2();
        this.choseall()
      } else if (this.changetype == "peer") {

        this.choseall()

      } else {
        this.suggest = [];
        this.array = [];
        this.inclu = [];
        this.allcheck = false;
        var all_class;
        if (this.changetype == "all") {
          all_class = 1;
        } else {
          all_class = 0;
        }
        this.maskshow = true;
        this.$http.post(this.GLOBAL.new_url + '/trademark/service/ai/getTrademarkRecommendData', {
          text: this.key,
          brandName: this.keyword,
          allClass: all_class,
          size: 10000,
          index: 1,
          userCode:this.$cookie.getCookie('u_id')
        }, {
          headers: {
            AuthToken: this.$cookie.getCookie('token'),
            'X-Request-Origin': 'xcx'
          }
        }).then(res => {
          console.log(res);
          if (res.data.code == "200") {
            if (res.data.data.one.length != 0) {
              this.suggest = res.data.data.one;
              this.recomedshow = true;
              var _this = this;
              // 给推荐的一级数据里面添加属性
              this.suggest.map(function (item) {
                _this.$set(item, 'isSelect1', false);
                _this.$set(item, 'chose', 0);
                _this.$set(item, 'price', 0);

              });
              this.getdata2();
              // 给推荐一级数据简洁的标题
              for (var i = 0; i < this.suggest.length; i++) {
                if(Number(this.suggest[i].num)<10){
                  this.suggest[i].num = "0"+Number(this.suggest[i].num)
                }
                // 根据星星给一级添加下拉框项对应的选项
                var star_i = [];
                var rate_i = [];
                for (var j = 0; j < this.suggest[i].sec.length; j++) {
                  if(Number(this.suggest[i].sec[j].num)<1000){
                    this.suggest[i].sec[j].num = "0"+Number(this.suggest[i].sec[j].num)
                  }
                  this.suggest[i].sec[j].score = Number(this.suggest[i].sec[j].score)
                  this.suggest[i].sec[j].randomNum = Number(this.suggest[i].sec[j].randomNum)
                  star_i.push(this.suggest[i].sec[j].score);
                  rate_i.push(this.suggest[i].sec[j].randomNum);
                  _this.$set(this.suggest[i].sec[j], 'isSelect2', false)

                }
                console.log(rate_i)
                // this.suggest[i].score = Math.max.apply(null, star_i);
                // this.suggest[i].rate = Math.max.apply(null, rate_i)
                this.suggest[i].score = 0;
                this.suggest[i].rate = 0
              }
              // this.choseall();
              // 把所有三级的id保存在数组判断右边变红
              var allName = []
              for (var i = 0; i < this.suggest.length; i++) {
                for (var j = 0; j < this.suggest[i].sec.length; j++) {
                  for (var m = 0; m < this.suggest[i].sec[j].third.length; m++) {
                    this.array.push(this.suggest[i].sec[j].third[m].code)
                    allName.push(this.suggest[i].sec[j].third[m].name)
                  }
                }
              }
              var jobName = this.key.split(",")
             
          for (let i = 0; i <jobName.length; i++) {
            for (let j = 0; j < allName.length; j++) {
              if(jobName[i]==allName[j]){
                this.inclu.push(jobName[i]);
              }
          }
          }
  
              console.log(this.inclu)
            } else {
              this.recomedshow = false;
              this.maskshow = false
              this.tip('error', '未检索到数据')
              //  this.suggest = res.data.data.data;
            }
          }
        })
      }
    },
    getdata2() {
      this.$http.post(this.GLOBAL.new_url + '/goods/service/categorys/queryCategorys', {
        categoryName: this.searchkeyword
      }).then(res => {
        if (res.data.code == '200') {
          console.log(res)
          this.threelink = res.data.data;
        
          if (this.keyword != ''&&this.threelink.length!=0) {
            this.$http.post('https://aipro.guolaoai.com/newRate/rate', {
              text: this.keyword
            }).then(res => {
              console.log(res)
              if (res.data.code == '200') {
                for (var i in res.data.data) {
                  this.star.push({code: i, score: Number(res.data.data[i])})
                }
                this.$http.post(this.GLOBAL.new_url + '/trademark/service/ai/queryRandomIssuingRate', {}, {
                  headers: {
                    AuthToken: this.$cookie.getCookie('token'),
                    'X-Request-Origin': 'xcx'
                  }
                }).then(res => {
                  // console.log(res)
                  if (res.data.code == '200') {
                    this.maskshow = false;
                    this.radom = res.data.data;
                    // console.log(this.radom)
                    for (var i = 0; i < this.threelink.length; i++) {
                      if (Number(this.threelink[i].categoryCode) < 10) {
                        this.threelink[i].categoryCode = '0' + Number(this.threelink[i].categoryCode)
                      }
                      for (var j = 0; j < this.threelink[i].categorysList.length; j++) {
                        for (var k = 0; k < this.star.length; k++) {
                          if (this.star[k].code == this.threelink[i].categorysList[j].categoryCode) {
                            this.threelink[i].categorysList[j].score = this.star[k].score
                            this.threelink[i].categorysList[j].rate = Number(this.radom[this.star[k].score])
                          }
                        }
                        if (Number(this.threelink[i].categorysList[j].categoryCode < 1000)) {
                          this.threelink[i].categorysList[j].categoryCode = '0' + Number(this.threelink[i].categorysList[j].categoryCode);
                        }
                      }
                    }
                    for (var i = 0; i < this.suggest.length; i++) {
                     
                      for (var j = 0; j < this.suggest[i].sec.length; j++) {
                        for (var k = 0; k < this.star.length; k++) {
                          if (Number(this.star[k].code) == Number(this.suggest[i].sec[j].num)) {
                            this.suggest[i].sec[j].score = this.star[k].score
                            this.suggest[i].sec[j].randomNum = Number(this.radom[this.star[k].score])
                          }
                        }
                        
                      }
                    }
                  }
                })
              }
            }).catch((err) =>{
                this.maskshow = false
                this.recomedshow = false;
                console.log(err);
            });
          } else {
            for (var i = 0; i < this.threelink.length; i++) {
              if (Number(this.threelink[i].categoryCode) < 10) {
                this.threelink[i].categoryCode = '0' + Number(this.threelink[i].categoryCode)
              }
              for (var j = 0; j < this.threelink[i].categorysList.length; j++) {
                if (Number(this.threelink[i].categorysList[j].categoryCode < 1000)) {
                  this.threelink[i].categorysList[j].categoryCode = '0' + Number(this.threelink[i].categorysList[j].categoryCode);
                  this.threelink[i].categorysList[j].score = 0;
                  this.threelink[i].categorysList[j].rate = 0;
                }
              }
            }
            this.maskshow = false
          }
        }
        // console.log(this.threelink)
      })
    },
    getdata3() {
      this.$http.post(this.GLOBAL.new_url + '/goods/service/goods/queryGoodsList', {
        goodsLevel: '2',
        userCode: this.$cookie.getCookie('u_id')
      }).then(res => {
        // console.log(res)
        if (res.data.code == "200") {
          this.options = res.data.data;
          const agent = window.localStorage.getItem('UserAgent')
          if (this.$cookie.getCookie('user_type') == 1) {
            for (var i = 0; i < this.options.length; i++) {
              if (this.$route.query.goodsName == this.options[i].goodsName) {
                if(agent == 'HT'){
                  this.basePrice = Number(this.options[i].proxyPrice)+100
                }else{
                  this.basePrice = Number(this.options[i].proxyPrice)
                }
                
                this.littleprice = Number(this.options[i].commonLittlePrice);
                this.goodsCode = this.options[i].goodsCode;
              }
            }
          } else if (this.$cookie.getCookie('type') == 2) {
            for (var i = 0; i < this.options.length; i++) {
              if (this.$route.query.goodsName == this.options[i].goodsName) {
                if(agent == 'HT'){
                  this.basePrice = Number(this.options[i].proxyPrice)+100
                }else{
                  this.basePrice = Number(this.options[i].proxyPrice)
                }
                this.littleprice = Number(this.options[i].commonLittlePrice);
                this.goodsCode = this.options[i].goodsCode;
              }
            }
          } 
          // else if (this.$cookie.getCookie('type') == 3 && this.$route.query.goodsName == '顾问商标注册') {
          //   for (var i = 0; i < this.options.length; i++) {
          //     if (this.$route.query.goodsName == this.options[i].goodsName) {
          //       this.basePrice = Number(this.options[i].goodsPrice) + Number(this.options[i].servicePrice) + 100
          //       this.littleprice = Number(this.options[i].commonLittlePrice);
          //       this.goodsCode = this.options[i].goodsCode;
          //     }
          //   }
          // } 
          else {
            for (var i = 0; i < this.options.length; i++) {
              if (this.$route.query.goodsName == this.options[i].goodsName) {
                if(agent == 'HT'){
                  this.basePrice = Number(this.options[i].proxyPrice)+100
                }else{
                  this.basePrice = Number(this.options[i].proxyPrice)
                }
                this.littleprice = Number(this.options[i].commonLittlePrice);
                this.goodsCode = this.options[i].goodsCode;
              }
            }
          }
          this.getdata1();
        }
      })
    },
    //   根据推荐所有的id的数组判断左边的三级是否变红
    isexit(val) {
      if (this.array.includes(String(val))) {
        return true;
      } else {
        return false;
      }
    },
    //   判断价格说明的显示隐藏
    showprice() {
      this.priceshow = !this.priceshow;
    },
    //  点击右边推荐的第一大类的多选判断二类的多选的状态和全选状态
    selectfirst(isSelect1, index) {
      if (isSelect1 == false) {
        for (var i = 0; i < this.suggest[index].sec.length; i++) {
          this.suggest[index].sec[i].isSelect2 = !isSelect1;
        }
        this.suggest[index].isSelect1 = true;
        var all = []
        for (var i = 0; i < this.suggest.length; i++) {
          all.push(this.suggest[i].isSelect1);
          for (var j = 0; j < this.suggest[i].sec.length; j++) {
            all.push(this.suggest[i].sec[j].isSelect2)
          }
        }
        if (all.indexOf(false) == -1) {
          this.allcheck = !this.allcheck;
        }
        //   实时检测大类星级和下证率的变化
        var star = [];
        var rate = [];
        for (var i = 0; i < this.suggest[index].sec.length; i++) {
          if (this.suggest[index].sec[i].isSelect2 == true) {
            star.push(this.suggest[index].sec[i].score);
            rate.push(this.suggest[index].sec[i].randomNum);
          }
        }
        this.suggest[index].score = Math.max.apply(null, star);
        this.suggest[index].rate = Math.max.apply(null, rate);


        // 判断选了多少项和计算价格
        var m_index = 0;
        for (var i = 0; i < this.suggest[index].sec.length; i++) {
          m_index += this.suggest[index].sec[i].third.length;
        }
        this.suggest[index].chose = m_index;
        if (m_index > 10) {
          this.suggest[index].price = this.basePrice + (m_index - 10) * this.littleprice;
        } else if (0 < m_index && m_index <= 10) {
          this.suggest[index].price = this.basePrice
        } else {
          this.suggest[index].price = 0;
        }

      } else {
        for (var i = 0; i < this.suggest[index].sec.length; i++) {
          this.suggest[index].sec[i].isSelect2 = !isSelect1;
        }
        this.suggest[index].isSelect1 = false;
        this.allcheck = false;
        //   实时检测改变服务类型的变化


        this.suggest[index].score = 0;
        this.suggest[index].rate = 0;

        // 判断选了多少项和计算价格
        this.suggest[index].chose = 0;
        this.suggest[index].price = 0;
      }
    },
    //   点击右边推荐的第二大类多选判断三类多选的状态
    selectsecond(isSelect2, index, k) {
      if (isSelect2 == false) {
        this.suggest[index].sec[k].isSelect2 = true;
        var m_index = []
        for (var i = 0; i < this.suggest[index].sec.length; i++) {
          if (this.suggest[index].sec[i].isSelect2 == true) {
            m_index.push(this.suggest[index].sec[i].isSelect2);
          }
        }
        if (m_index.length == this.suggest[index].sec.length) {
          this.suggest[index].isSelect1 = true;
        }
        var all = []
        for (var i = 0; i < this.suggest.length; i++) {
          all.push(this.suggest[i].isSelect1);
          for (var j = 0; j < this.suggest[i].sec.length; j++) {
            all.push(this.suggest[i].sec[j].isSelect2)
          }
        }
        if (all.indexOf(false) == -1) {
          this.allcheck = !this.allcheck;
        }
        //   实时检测大类星级和下证率的变化
        var star = [];
        var rate = [];
        for (var i = 0; i < this.suggest[index].sec.length; i++) {
          if (this.suggest[index].sec[i].isSelect2 == true) {
            star.push(this.suggest[index].sec[i].score)
            rate.push(this.suggest[index].sec[i].randomNum);
          }
        }
        this.suggest[index].score = Math.max.apply(null, star);
        this.suggest[index].rate = Math.max.apply(null, rate);

        // 判断选了多少项和计算价格
        var m_index = 0;
        for (var i = 0; i < this.suggest[index].sec.length; i++) {
          if (this.suggest[index].sec[i].isSelect2 == true) {
            m_index += this.suggest[index].sec[i].third.length;
          }
        }
        this.suggest[index].chose = m_index;
        if (m_index > 10) {
          this.suggest[index].price = this.basePrice + (m_index - 10) * this.littleprice;
        } else if (0 < m_index && m_index <= 10) {
          this.suggest[index].price = this.basePrice;
        } else {
          this.suggest[index].price = 0;
        }

      } else {
        //    console.log(11111)
        this.suggest[index].sec[k].isSelect2 = false;
        this.suggest[index].isSelect1 = false;
        this.allcheck = false;
        //   实时检测改变服务类型的变化
        var star = [];
        var rate = [];
        var all = [];
        for (var i = 0; i < this.suggest[index].sec.length; i++) {
          all.push(this.suggest[index].sec[i].isSelect2);
          if (this.suggest[index].sec[i].isSelect2 == true) {
            star.push(this.suggest[index].sec[i].score)
            rate.push(this.suggest[index].sec[i].randomNum)
          }

        }
        this.suggest[index].score = Math.max.apply(null, star);
        this.suggest[index].rate = Math.max.apply(null, rate);

        if (all.indexOf(true) == -1) {
          this.suggest[index].score = 0;
          this.suggest[index].rate = 0;
        }
        // 判断选了多少项和计算价格
        var m_index = 0;
        for (var i = 0; i < this.suggest[index].sec.length; i++) {
          if (this.suggest[index].sec[i].isSelect2 == true) {
            m_index += this.suggest[index].sec[i].third.length;
          }
        }
        this.suggest[index].chose = m_index;

        if (m_index > 10) {
          this.suggest[index].price = this.basePrice + (m_index - 10) * this.littleprice;
        } else if (0 < m_index && m_index <= 10) {
          this.suggest[index].price = this.basePrice;
        } else {
          this.suggest[index].price = 0;
        }
      }
    },
    //   点击全选的状态判断第一类的多选状态
    choseall() {
      if (this.allcheck == true) {
        for (var i = 0; i < this.suggest.length; i++) {
          this.suggest[i].isSelect1 = false;
          for (var j = 0; j < this.suggest[i].sec.length; j++) {
            this.suggest[i].sec[j].isSelect2 = false
          }
        }
        this.allcheck = false
        //   判断多少项和价格
        for (var i = 0; i < this.suggest.length; i++) {
          this.suggest[i].chose = 0;
          this.suggest[i].price = 0;
          this.suggest[i].score = 0;
          this.suggest[i].rate = 0;
        }
      } else {
        for (var i = 0; i < this.suggest.length; i++) {
          this.suggest[i].isSelect1 = true;
          for (var j = 0; j < this.suggest[i].sec.length; j++) {
            this.suggest[i].sec[j].isSelect2 = true
          }
        }
        this.allcheck = true
        //   判断多少项和价格
        var star = [];
        var rate = [];
        for (var i = 0; i < this.suggest.length; i++) {
          var m_i = 0;
          star[i] = [];
          rate[i] = [];
          for (var j = 0; j < this.suggest[i].sec.length; j++) {
            m_i += this.suggest[i].sec[j].third.length;
            star[i].push(this.suggest[i].sec[j].score)
            rate[i].push(this.suggest[i].sec[j].randomNum)
          }
          this.suggest[i].score = Math.max.apply(null, star[i]);
          this.suggest[i].rate = Math.max.apply(null, rate[i]);
          this.suggest[i].chose = m_i;

          if (m_i > 10) {
            this.suggest[i].price = this.basePrice + (m_i - 10) * this.littleprice;
          } else {
            this.suggest[i].price = this.basePrice;
          }
        }

      }
    },
    //   点击右边推荐三类的x删除数组里的数据和计算价格和选项
    // item.num,v.num,index,n,m.id,k
    del(val1, val2, val3, val4, val5, val6) {

        // 把三类的id从获取推荐所有三类id的数组中删除，左边相对应的三级不变红
      for(var i = this.array.length-1;i>=0;i--){
        if (val5 == this.array[i]) {
          this.$delete(this.array, i);
        }
      }
      //  console.log(this.array)
    for(var i = this.selfAdd.length-1;i>=0;i--){
        if (val5 == this.selfAdd[i]) {
          this.$delete(this.selfAdd, i);
        }
      }


      var star = [];
      var rate = [];
      for (var j = 0; j < this.suggest[val3].sec.length; j++) {
        if (val2 == this.suggest[val3].sec[j].num) {
          this.$delete(this.suggest[val3].sec[j].third, val4);

        }

        if (this.suggest[val3].sec[j].isSelect2 == true && this.suggest[val3].sec[j].third.length != 0) {
          star.push(this.suggest[val3].sec[j].score)
          rate.push(this.suggest[val3].sec[j].randomNum)
        }
      }

      for(let i = this.suggest[val3].sec.length-1;i>=0;i--){
          if (this.suggest[val3].sec[i].third.length == 0) {
          this.suggest[val3].sec.splice(val6, 1)
        }
         if (this.suggest[val3].sec.length == 0) {
          this.suggest.splice(val3, 1)
        }
        }
        if(this.suggest.length!=0){
          if (this.suggest[val3].isSelect1 == false) {
            this.suggest[val3].score = 0;
            this.suggest[val3].rate = 0;
          } else {
            this.suggest[val3].score = Math.max.apply(null, star);
            this.suggest[val3].rate = Math.max.apply(null, rate);
          }
        }
      

  //  console.log(this.suggest)
      // 计算价格和多少项
      var arr_val3 = 0;
      if(this.suggest.length!=0){
        for (var i = 0; i < this.suggest[val3].sec.length; i++) {
        if (this.suggest[val3].sec[i].isSelect2 == true) {
          for (var j = 0; j < this.suggest[val3].sec[i].third.length; j++) {
            arr_val3 += 1
          }
        }
      }
      this.suggest[val3].chose = arr_val3;
      if (arr_val3 > 10) {
        this.suggest[val3].price = this.basePrice + (arr_val3 - 10) * this.littleprice;
      } else if (0 < arr_val3 && arr_val3 <= 10) {
        this.suggest[val3].price = this.basePrice;
      } else {
        this.suggest[val3].price = 0;
      }
      }
      // console.log(this.array)
      
    },
    //   点击左边三级自动添加到右边推荐数组里面
    //v.categoryCode,v.categoryName,item.categoryCode,item.categoryName,item.score,m.code,m.categoryName,item.rate
    addcate(val1, val2, val3, val4, val5, val6, val7, val8) {
      
      // 判断新增的小类是否存在
      var Name = []
      for(var i =0;i<this.suggest.length;i++){
        for(var j =0;j<this.suggest[i].sec.length;j++){
          for(var m =0;m<this.suggest[i].sec[j].third.length;m++){
            Name.push(this.suggest[i].sec[j].third[m].name)
          }
        }
      }
      if(Name.indexOf(val7) != -1){
        this.tip('error','您已选择过该商品'+'“'+val7+'”')
      }
      // console.log(Name)
      if (this.suggest.length != 0) {
        for (var i = 0; i < this.array.length; i++) {
          if (val6 == this.array[i]) {
            return false
          }
        }
        var str1 = [];
        var str2 = []
        for (var i = 0; i < this.suggest.length; i++) {
          str1.push(this.suggest[i].num);
          for (var j = 0; j < this.suggest[i].sec.length; j++) {
            str2.push(this.suggest[i].sec[j].num);
          }
        }
        if (this.$route.query.shop_id) {
          if (str1.indexOf(val1) == -1) {
            this.tip('error', '请选择同大类的小商品')
            return false
          } else {
            if (str2.indexOf(val3) != -1) {
              let objcate3 = {code: val6, name: val7}
              for (var i = 0; i < this.suggest.length; i++) {
                for (var j = 0; j < this.suggest[i].sec.length; j++) {
                  if (val3 == this.suggest[i].sec[j].num) {
                    this.suggest[i].sec[j].third.push(objcate3)
                    this.array.push(String(val6))
                    this.isexit(String(val6))
                    if(this.changetype != 'chose'){
                      this.selfAdd.push(val6)
                      this.isSeft(val6)
                    }
                  }
                }
              }
            } else {
              let objcate2 = {
                isSelect2: true,
                name: val4,
                num: val3,
                score: val5,
                randomNum: val8,
                third: [{code: val6, name: val7}]
              }
              for (var i = 0; i < this.suggest.length; i++) {
                if (val1 == this.suggest[i].num) {
                  this.suggest[i].sec.push(objcate2)
                  this.array.push(String(val6))
                  this.isexit(String(val6))
                  this.suggest[i].score = val5
                  this.suggest[i].rate = val8
                  if(this.changetype != 'chose'){
                      this.selfAdd.push(val6)
                      this.isSeft(val6)
                    }
                }
              }
            }
          }
        } else {
          if (str1.indexOf(val1) != -1) {
            if (str2.indexOf(val3) != -1) {
              let objcate3 = {code: val6, name: val7}
              for (var i = 0; i < this.suggest.length; i++) {
                for (var j = 0; j < this.suggest[i].sec.length; j++) {
                  if (val3 == this.suggest[i].sec[j].num) {
                    this.suggest[i].sec[j].third.push(objcate3)
                    this.array.push(String(val6))
                    this.isexit(String(val6))
                    if(this.changetype != 'chose'){
                      this.selfAdd.push(val6)
                      this.isSeft(val6)
                    }
                  }
                }
              }
            } else {
              let objcate2 = {
                isSelect2: true,
                name: val4,
                num: val3,
                score: val5,
                randomNum: val8,
                third: [{code: val6, name: val7}]
              }

              for (var i = 0; i < this.suggest.length; i++) {
                if (val1 == this.suggest[i].num) {
                  this.suggest[i].sec.push(objcate2)
                  this.array.push(String(val6))
                  this.isexit(String(val6))
                  this.suggest[i].score = val5
                  this.suggest[i].rate = val8
                  if(this.changetype != 'chose'){
                      this.selfAdd.push(val6)
                      this.isSeft(val6)
                    }
                }
              }

            }
          } else {
            let objcate = {
              chose: 0, isSelect1: true, price: 0, num: val1, name: val2, score: val5, rate: val8,
              sec: [{
                isSelect2: true,
                name: val4,
                num: val3,
                score: val5,
                randomNum: val8,
                third: [{code: val6, name: val7}]
              }]
            };

            this.suggest.push(objcate)
            this.array.push(String(val6))
            this.isexit(String(val6))
            if(this.changetype != 'chose'){
                  this.selfAdd.push(val6)
                  this.isSeft(val6)
                }
          }
        }
      } else {
        if (this.$route.query.shop_id) {
          if (this.$route.query.oneCate == val1) {
            let objcate = {
              chose: 0, isSelect1: true, price: 0, num: val1, name: val2, score: val5, rate: val8,
              sec: [{
                isSelect2: true,
                name: val4,
                num: val3,
                score: val5,
                randomNum: val8,
                third: [{code: val6, name: val7}]
              }]
            };

            this.suggest.push(objcate)
            this.array.push(String(val6))
            this.isexit(String(val6))
            if(this.changetype != 'chose'){
                      this.selfAdd.push(val6)
                      this.isSeft(val6)
                    }
          } else {
            this.tip('error', '请选择同大类的小商品')
            return false
          }
        } else {
          let objcate = {
            chose: 0, isSelect1: true, price: 0, num: val1, name: val2, score: val5, rate: val8,
            sec: [{
              isSelect2: true,
              name: val4,
              num: val3,
              score: val5,
              randomNum: val8,
              third: [{code: val6, name: val7}]
            }]
          };

          this.suggest.push(objcate)
          this.array.push(String(val6))
          this.isexit(String(val6))
          if(this.changetype != 'chose'){
                      this.selfAdd.push(val6)
                      this.isSeft(val6)
                    }
        }

      }
      //  计算价格和选项
      for (var i = 0; i < this.suggest.length; i++) {
        if (val1 == this.suggest[i].num) {
          var total_i = 0;
          for (var j = 0; j < this.suggest[i].sec.length; j++) {
            if (this.suggest[i].sec[j].isSelect2 == true) {
              total_i += this.suggest[i].sec[j].third.length;
            }
          }
          this.suggest[i].chose = total_i;
          if (total_i > 10) {
            this.suggest[i].price = this.basePrice + (total_i - 10) * this.littleprice;
          } else if (0 < total_i && total_i <= 10) {
            this.suggest[i].price = this.basePrice
          } else {
            this.suggest[i].price = 0;
          }
        }
      }
    },
    //   点击大类垃圾桶
    delfirst(index) {
      var threenum = []
      for (var j = 0; j < this.suggest[index].sec.length; j++) {
        for (var m = 0; m < this.suggest[index].sec[j].third.length; m++) {
          threenum.push(this.suggest[index].sec[j].third[m].code)
        }
      }
      for (var i = 0; i < threenum.length; i++) {
        for (var j = 0; j < this.array.length; j++) {
          if (threenum[i] == this.array[j]) {
            this.array.splice(j, 1);
          }
        }
        for (var j = 0; j < this.selfAdd.length; j++) {
          if (threenum[i] == this.selfAdd[j]) {
            this.selfAdd.splice(j, 1);
          }
        }
      }
      this.suggest.splice(index, 1)
    },
    //   点击组别垃圾桶
    delsecond(index, k) {
      var threenum = []
      for (var m = 0; m < this.suggest[index].sec[k].third.length; m++) {
        threenum.push(this.suggest[index].sec[k].third[m].code)
      }
      for (var i = 0; i < threenum.length; i++) {
        for (var j = 0; j < this.array.length; j++) {
          if (threenum[i] == this.array[j]) {
            this.array.splice(j, 1);
          }
        }
        for (var j = 0; j < this.selfAdd.length; j++) {
          if (threenum[i] == this.selfAdd[j]) {
            this.selfAdd.splice(j, 1);
          }
        }
      }
      this.suggest[index].sec.splice(k, 1)
      //  计算价格和选项
      if (this.suggest[index].sec.length == 0) {
        this.suggest[index].chose = 0;
        this.suggest[index].price = 0;
        this.suggest.splice(index, 1)
      } else {
        //   实时检测改变服务类型的变化
        var star = [];
        var rate = [];
        var all = [];
        for (var i = 0; i < this.suggest[index].sec.length; i++) {
          all.push(this.suggest[index].sec[i].isSelect2);
          if (this.suggest[index].sec[i].isSelect2 == true) {
            star.push(this.suggest[index].sec[i].score)
            rate.push(this.suggest[index].sec[i].randomNum)

          }
        }
        this.suggest[index].score = Math.max.apply(null, star);
        this.suggest[index].rate = Math.max.apply(null, rate);
        if (all.indexOf(true) == -1) {
          this.suggest[index].score = 0;
          this.suggest[index].rate = 0;
        }
        // 判断选了多少项和计算价格
        var total = 0;
        for (var i = 0; i < this.suggest[index].sec.length; i++) {
          if (this.suggest[index].sec[i].isSelect2 == true) {
            total += this.suggest[index].sec[i].third.length;
          }
        }
        this.suggest[index].chose = total;
        if (total > 10) {
          this.suggest[index].price = this.basePrice + (total - 10) * this.littleprice;
        } else if (0 < total && total <= 10) {
          this.suggest[index].price = this.basePrice;
        } else {
          this.suggest[index].price = 0;
        }
      }
    },
    //   查看近似商标
    like(val1, val2) {
      if (this.$route.query.shop_id) {
        let routeData = this.$router.resolve({
          path: '/like', query: {
            type: this.keyword, cate_id: val1,
            num: val2
          }
        });
        window.open(routeData.href, '_blank');
      } else {
        let routeData = this.$router.resolve({
          path: '/like', query: {
            type: this.keyword, cate_id: val1,
            num: val2
          }
        });
        window.open(routeData.href, '_blank');
      }
    },
    // 底部删除
    deldata() {
      var all = []
      for (var i = 0; i < this.suggest.length; i++) {
        all.push(this.suggest[i].isSelect1);
        for (var j = 0; j < this.suggest[i].sec.length; j++) {
          all.push(this.suggest[i].sec[j].isSelect2)
        }
      }
      if (all.indexOf(true) == -1) {
        this.tip('error', '请选择删除类别')
        return false
      } else {
        this.dialogVisible = true;
      }
    },
    //   收藏
    collect() {
      var haveTrue = [];
      const newArr = JSON.parse(JSON.stringify(this.suggest))
      for (var i = newArr.length - 1; i >= 0; i--) {
        for (var j = newArr[i].sec.length - 1; j >= 0; j--) {
          if (newArr[i].sec[j].isSelect2 == false) {
            newArr[i].sec.splice(j, 1)
            if (newArr[i].sec.length == 0) {
              newArr.splice(i, 1)
            }
          }
        }
      }
      for (var i = 0; i < newArr.length; i++) {
        for (var j = 0; j < newArr[i].sec.length; j++) {
          haveTrue.push(newArr[i].sec[j].isSelect2);
        }
        if (newArr[i].sec.length == 0) {
          newArr.splice(i, 1)
        }
      }
      const newArr1 = JSON.parse(JSON.stringify(newArr)).map((x, y) => {
        return x = {
          num: x.num,
          name: x.name,
          isSelect1: x.isSelect1,
          chose: x.chose,
          price: x.price,
          second: x.sec.map((a, b) => {
            return a = {
              num: a.num,
              code: a.id,
              name: a.name,
              isSelect2: true,
              three: a.third.map((i, o) => {
                return i = {
                  id: i.code,
                  num: i.num,
                  name: i.name,

                };
              })
            };
          })
        };
      });

      // console.log(newArr1)
      if (newArr.length != 0 && haveTrue.indexOf(true) != -1) {
        this.$http.post(this.GLOBAL.new_url + '/trademarkhiscoll/webapi/generator', {
          trademarkImgUrl: this.srcurl,
          trademarkName: this.keyword,
          trademarkHisColType: 2,
          trademarkBody: JSON.stringify(newArr1)
        }, {
          headers: {
            AuthToken: this.$cookie.getCookie('token'),
            'X-Request-Origin': 'xcx'
          }
        }).then(res => {
          console.log(res)
          if (res.data.code == 200) {
            this.tip('success', '收藏成功');
          } else {
            this.tip('error', res.data.msg)
          }
        })
      } else {
        this.tip('error', '请选择收藏的类别')
        return false
      }

    },
    //   点击删除把选中的项从推荐的数组中删除
    confireQuit() {
      //   删除让左边不变红
      var threenum = []
      for (var i = 0; i < this.suggest.length; i++) {
        for (var j = 0; j < this.suggest[i].sec.length; j++) {
          if (this.suggest[i].sec[j].isSelect2 == true) {
            for (var m = 0; m < this.suggest[i].sec[j].third.length; m++) {
              threenum.push(this.suggest[i].sec[j].third[m].code)
            }
          }
        }
      }
      for (var i = 0; i < threenum.length; i++) {
        for (var j = 0; j < this.array.length; j++) {
          if (threenum[i] == this.array[j]) {
            this.array.splice(j, 1);
          }
        }
        for (var j = 0; j < this.selfAdd.length; j++) {
          if (threenum[i] == this.selfAdd[j]) {
            this.selfAdd.splice(j, 1);
          }
        }
      }
      //   从推荐数组中删除选中的数据
      for (var i = this.suggest.length - 1; i >= 0; i--) {
        if (this.suggest[i].isSelect1 == true) {
          this.suggest.splice(i, 1)
        } else {
          for (var j = this.suggest[i].sec.length - 1; j >= 0; j--) {
            if (this.suggest[i].sec[j].isSelect2 == true) {
              this.suggest[i].sec.splice(j, 1)
              this.suggest[i].chose = 0;
              this.suggest[i].price = 0;
            }
          }
        }
      }
      this.dialogVisible = false;
      //   实时检测改变服务类型的变化
      var star = [];
      var rate = [];
      var all = [];
      for (var i = 0; i < this.suggest.length; i++) {
        star[i] = [];
        rate[i] = [];
        for (j = 0; j < this.suggest[i].sec.length; j++) {
          star[i].push(this.suggest[i].sec[j].score)
          rate[i].push(this.suggest[i].sec[j].randomNum)
          all.push(this.suggest[i].sec[j].isSelect2);
        }
        this.suggest[i].score = Math.max.apply(null, star[i]);
        this.suggest[i].rate = Math.max.apply(null, rate[i]);
        if (all.indexOf(true) == -1) {
          this.suggest[i].score = 0;
          this.suggest[i].rate = 0;
        }
      }
    },
    // 加入购物车
    addcart() {
      if (this.$route.query.shop_id) {
        var haveTrue = [];
        const newArr = JSON.parse(JSON.stringify(this.suggest))
        for (var i = newArr.length - 1; i >= 0; i--) {
          for (var j = newArr[i].sec.length - 1; j >= 0; j--) {
            if (newArr[i].sec[j].isSelect2 == false) {
              newArr[i].sec.splice(j, 1)
              if (newArr[i].sec.length == 0) {
                newArr.splice(i, 1)
              }
            }
          }
        }
        for (var i = 0; i < newArr.length; i++) {
          for (var j = 0; j < newArr[i].sec.length; j++) {
            haveTrue.push(newArr[i].sec[j].isSelect2);
          }
          if (newArr[i].sec.length == 0) {
            newArr.splice(i, 1)
          }
        }
        //    console.log(newArr)
        if (newArr.length != 0 && haveTrue.indexOf(true) != -1) {
          let data = {
            cartCode: this.$route.query.shop_id,
            goodsCode: this.goodsCode,
            userCode: this.$cookie.getCookie('u_id'),
            goodsName: this.$route.query.goodsName,
            trademarkImgUrl: this.$route.query.url,
            trademarkName: this.$route.query.name,
            orderPrice: newArr[0].price,
            successRate: newArr[0].score,
            cardChange: newArr[0].rate,
            cartInfoVOList: [{
              collectCate: newArr[0].num,
              collectName: newArr[0].name,
              pCollectCate: "",
              successRateInfo: newArr[0].score,
              cardChangeInfo: newArr[0].rate,
              categoryCode: '',
            },
              ...newArr[0].sec.map((a, b) => {
                let arr = a;
                return a = [{
                  collectCate: a.num,
                  collectName: a.name,
                  pCollectCate: newArr[0].num,
                  successRateInfo: a.score,
                  cardChangeInfo: a.randomNum,
                  categoryCode: a.code,
                },
                  ...arr.third.map((i, o) => {
                    return i = {
                      collectCate: i.num,
                      collectName: i.name,
                      pCollectCate: a.num,
                      categoryCode: i.code,
                    }
                  })
                ]
              })
            ]
          };
          // console.log(data);
          let newArr1 = new Array(0);
          data.cartInfoVOList.map((i, o) => {
            if (i instanceof Array) {
              i.map((m, n) => {
                newArr1.push(m);
              });
            } else {
              newArr1.push(i);
            }
            ;

            data.cartInfoVOList = newArr1;
          });
          // console.log(data);
          this.$http.post(this.GLOBAL.new_url + '/trademark/service/cart/updateCart', data, {
            headers: {
              AuthToken: this.$cookie.getCookie('token'),
              'X-Request-Origin': 'xcx'
            }
          }).then(res => {
            // console.log(res)
            if (res.data.code == "200") {
              this.$router.push({
                path: '/personal/buyCart'
              })
            } else {
              this.tip('error', res.data.msg)
            }
          })

        }else {
          this.tip('error', '请先选择商品')
        }
      } else {
        var haveTrue = [];
        const newArr = JSON.parse(JSON.stringify(this.suggest))
        for (var i = newArr.length - 1; i >= 0; i--) {
          for (var j = newArr[i].sec.length - 1; j >= 0; j--) {
            if (newArr[i].sec[j].isSelect2 == false) {
              newArr[i].sec.splice(j, 1)
              if (newArr[i].sec.length == 0) {
                newArr.splice(i, 1)
              }
            }
          }
        }
        for (var i = 0; i < newArr.length; i++) {
          for (var j = 0; j < newArr[i].sec.length; j++) {
            haveTrue.push(newArr[i].sec[j].isSelect2);
          }
          if (newArr[i].sec.length == 0) {
            newArr.splice(i, 1)
          }
        }
        //    console.log(newArr)
        if (newArr.length != 0 && haveTrue.indexOf(true) != -1) {
          let data = {
            cartType: "1",
            typeName: '商标注册',
            cartVOList: newArr.map((x, y) => {
              return x = {
                userCode: this.$cookie.getCookie('u_id'),
                goodsCode: this.goodsCode,
                goodsName: this.$route.query.goodsName,
                trademarkImgUrl: this.srcurl,
                trademarkName: this.keyword,
                trademarkType: Number(this.picked1) - 1,
                orderPrice: x.price,
                successRate: x.score,
                cardChange: x.rate,
                topCollectCate: x.num,
                cartInfoVOList: [
                  {
                    collectCate: x.num,
                    collectName: x.name,
                  },
                  ...x.sec.map((a, b) => {
                    let arr = a;
                    return a = [
                      {
                        collectCate: a.num,
                        collectName: a.name,
                        categoryCode: a.code,
                        pCollectCate: x.num,
                        successRateInfo: a.score,
                        cardChangeInfo: a.randomNum,
                      },
                      ...arr.third.map((i, o) => {
                        return i = {
                          collectCate: i.num,
                          collectName: i.name,
                          pCollectCate: a.num,
                          categoryCode: i.code,
                        }
                      })
                    ]
                  })

                ]
              }
            })
          }
          // console.log(data);
          data.cartVOList.map((a, b) => {
            let newArr1 = new Array(0);
            a.cartInfoVOList.map((i, o) => {
              if (i instanceof Array) {
                i.map((m, n) => {
                  newArr1.push(m);
                });
              } else {
                newArr1.push(i);
              }
              ;
            })
            data.cartVOList[b].cartInfoVOList = newArr1;
          });
          // console.log(data);
          this.$http.post(this.GLOBAL.new_url + '/trademark/service/cart/addCart', data, {
            headers: {
              AuthToken: this.$cookie.getCookie('token'),
              'X-Request-Origin': 'xcx'
            }
          }).then(res => {
            // console.log(res)
            if (res.data.code == "200") {
              this.$router.push({
                path: '/personal/buyCart'
              })
            } else {
              this.tip('error', res.data.msg)
            }
          })

        } else {
          this.tip('error', '请先选择商品')
        }
      }

    },
    // 立即购买
    rightbuy() {
      var haveTrue = [];
      const newArr = JSON.parse(JSON.stringify(this.suggest))
      for (var i = newArr.length - 1; i >= 0; i--) {
        for (var j = newArr[i].sec.length - 1; j >= 0; j--) {
          if (newArr[i].sec[j].isSelect2 == false) {
            newArr[i].sec.splice(j, 1)
            if (newArr[i].sec.length == 0) {
              newArr.splice(i, 1)
            }
          }
        }
      }
      for (var i = 0; i < newArr.length; i++) {
        for (var j = 0; j < newArr[i].sec.length; j++) {
          haveTrue.push(newArr[i].sec[j].isSelect2);
        }
        if (newArr[i].sec.length == 0) {
          newArr.splice(i, 1)
        }
      }
      //    console.log(this.suggest)
      if (newArr.length != 0 && haveTrue.indexOf(true) != -1) {
        var list = []
        // var orderType = ""
        // if (this.$route.query.goodsName == '顾问商标注册') {
        //   orderType = 2
        // } else {
        //   orderType = 1
        // }
        list = newArr.map((x, y) => {
          return x = {
            serviceTypeName: this.$route.query.goodsName,
            goodsName: '商标注册',
            goodsType: "1",
            goodsCode: this.goodsCode,
            orderType: this.$route.query.goodsName,
            trademarkInfo: {
              oneCate: x.num,
              oneCateName: x.name,
              successRate: x.score,
              randomNum: x.rate,
              trademarkName: this.keyword,//商标名称
              trademarkImgUrl: this.srcurl, //商标图片URL
              trademarkType: Number(this.picked1) - 1,
              typeface: this.value, //字体类型
              trademarkColorType: this.trademarkColorType, //商标颜色类型1.黑白2.原色
              twoList: x.sec.map((a, b) => {
                return a = {
                  twoCate: a.num,
                  twoCateName: a.name,
                  // categoryCode:a.code,
                  threeList: a.third.map((i, o) => {
                    return i = {
                      groupName: i.name,
                      groupMark: i.num,
                      categoryCode: i.code,
                      twoCate: a.num,
                    };
                  })
                }
              }),
            },


          };
        })
        // console.log(list);
        // var agentType = '';
        if (this.$cookie.getCookie('u_id')) {
          this.$http.post(this.GLOBAL.new_url + '/transactionv2/webapi/insertOrder', {
            list: list
          }, {
            headers: {
              'X-User-Code': this.$cookie.getCookie('u_id'),
              AuthToken: this.$cookie.getCookie('token'),
              'X-Request-Origin': 'xcx'
            }
          }).then(res => {
            // console.log(res)
            if (res.data.code == "200") {
              var a = '';
              for (var i = 0; i < res.data.data.length; i++) {
                a += res.data.data[i].orderCode + ','
              }
              a = a.substring(0, a.length - 1);
              this.$router.push({
                path: '/checkOrder',
                query: {
                  order: a
                }
              })
            } else {
              this.tip('error', res.data.msg)
            }
          })
        }
      } else {
        this.tip('error', '请先选择商品')
      }
    },
   //  搜索45大类
    regsearch() {
     this.getdata2();
      if(this.searchkeyword != ''){
        for (var i = 0; i < this.threelink.length; i++) {
        this.firstid.push(this.threelink[i].categoryCode);
        for (var j = 0; j < this.threelink[i].categorysList.length; j++) {
          this.secondid.push(this.threelink[i].categorysList[j].categoryCode);
        }
      }
      }else{
        this.firstid = [];
        this.secondid = [];
      }
      
    },
    //   判断搜索数据的展开和隐藏（以下4个方法）
    stretch1(val) {
      if (this.firstid.includes(val)) {
        for (var i = 0; i < this.firstid.length; i++) {
          if (val == this.firstid[i]) {
            this.firstid.splice(i, 1)
          }
        }
      } else {
        this.firstid.push(val);
      }
    },
    stretch2(val) {
      if (this.secondid.includes(val)) {
        for (var i = 0; i < this.secondid.length; i++) {
          if (val == this.secondid[i]) {
            this.secondid.splice(i, 1)
          }
        }
      } else {
        this.secondid.push(val);
      }
    },
    isTags(val) {
      if (this.firstid.includes(val)) {
        return true;
      } else {
        return false;
      }
    },
    isTags2(val) {
      if (this.secondid.includes(val)) {
        return true;
      } else {
        return false;
      }
    },
    // 判断是否是自己增类
    isSeft(val){
      if (this.selfAdd.includes(val)) {
        return true;
      } else {
        return false;
      }
    },
    isinclude(val){
      if (this.inclu.includes(val)) {
        return true;
      } else {
        return false;
      }
    }
  },
  mounted() {
    this.getfont();
    this.gethistorydata();
    if (this.$route.query.shop_id) {
      this.buttonshow = false;
      this.nameshow = false;
      this.recomedshow = true;
      this.selectCollectM = false;
      this.getdata3();
    }
    this.$http.post(this.GLOBAL.new_url + '/user/service/getPersonInfo', {
      userCode: this.$cookie.getCookie('u_id')
    }, {
      headers: {
        AuthToken: this.$cookie.getCookie('token'),
        'X-Request-Origin': 'xcx'
      }
    }).then(res => {
      // console.log(res)
      if (res.data.code == "200") {
        this.personInfo = res.data.data;
      }

    })
  },
  created() {

  },
  destroyed() {

  }
}

</script>

<style scoped>
.recome-box {
  width: 100%;
  height: 100%;
  background: #fcfcfc;
}

.recome-middle {
  width: 1200px;
  padding-bottom: 80px;
  margin: 0 auto;
}

.recome-middle-top {
  width: 1200px;
  height: 130px;
  box-shadow: 3px 5px 19px -1px rgba(217, 217, 217, 0.5);
  background-color: white;
  margin-bottom: 48px;
}

.recome-middle-top > div {
  float: left;
  /* width: 180px; */
  height: 50px;
  font-size: 36px;
  font-weight: 500;
  color: black;
  margin: 40px 0 0 511px;
}

.recome-middle-top > img {
  width: 58px;
  height: 68px;
  float: left;
  margin: 27px 0 0 204px;
}

.recome-name {
  width: 1200px;
  /* height: 840px; */
  margin-bottom: 48px;
}

.recome-name-title {
  width: 336px;
  height: 60px;
  margin: 0 auto 32px;
  font-size: 28px;
  line-height: 2.14;
  text-align: center;
  color: black;
}

.recome-name-picktype {
  width: 1200px;
  padding-top: 55px;
  padding-bottom: 50px;
  box-shadow: 3px 5px 19px -1px rgba(217, 217, 217, 0.5);
  background-color: white;
}

.recome-name-picktype-detail {
  width: 948px;
  margin: 0 auto;
}

.recome-pickwho {
  height: 50px;
  margin-bottom: 30px;
  position: relative;
}

.recome-pickwho1 {
  margin-left: 18px;
  font-size: 18px;
}

.recome-pickwho1 > input {
  width: 275px;
  height: 48px;
  padding-left: 10px;
  font-size: 18px;
  border-radius: 4px;
  margin-left: 36px;
  outline: none;
  border: solid 1px #d6d6d6;
}

.recome-pickwho2 {
  position: absolute;
  width: 284px;
  height: 169px;
  background: #FFFFFF;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.08);
  border: 1px solid #DADCE1;
  overflow-y: auto;
  padding: 8px 0;
  left: 113px;
  top: 58px;
}

.recome-pickwho2::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.recome-pickwho2::-webkit-scrollbar-thumb {
  border-radius: 5px;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
}

.recome-pickwho2::-webkit-scrollbar-track {
  opacity: 0;
}

.recome-pickhome-phone {
  font-size: 14px;
  line-height: 35px;
  padding-left: 24px;
  height: 35px;
  cursor: pointer;
}

.recome-pickhome-phone:hover {
  background-color: #EB5E00;
}

.trigonometry {
  position: absolute;
  width: 0;
  height: 0;
  top: 56px;
  left: 165px;
  border-style: solid;
  border-width: 5px;
  border-color: transparent transparent #fff #fff;
  transform: rotate(135deg);
  -webkit-box-shadow: -2px 2px 3px 0 rgba(0, 0, 0, 0.2);
  -moz-box-shadow: -2px 2px 3px 0 rgba(0, 0, 0, 0.2);
  box-shadow: -2px 2px 3px 0 rgba(0, 0, 0, 0.2);
  z-index: 9977;
  z-index: 9977;
}

.detail-div1 {
  height: 32px;
  line-height: 32px;
  margin-bottom: 44px;
}

.detail-div1 > span, .detail-div2-img > span {
  font-size: 18px;
  color: black;
  margin-right: 160px;
  vertical-align: middle;
}

.detail-div1 > span:nth-of-type(1), .detail-div2-img > span:nth-of-type(1) {
  margin-right: 80px;
}

.detail-div1 > span:nth-of-type(4) {
  margin-right: 0;
}

.detail-div1 > input, .detail-div2-img > input {
  width: 16px;
  height: 16px;
  vertical-align: middle;
  margin-right: 16px;
  cursor: pointer;
}

.detail-div2, .detail-tuxing, .detail-tuwen {
  /* border:1px solid red; */
  height: 460px;
  margin-bottom: 60px;
}

.detail-div2-writename {
  height: 50px;
  margin-bottom: 44px;
}

.detail-div2-writename > span {
  vertical-align: middle;
  font-size: 18px;
  color: black;
  margin-right: 36px;
}

.detail-div2-writename > input {
  width: 820px;
  padding-left: 10px;
  height: 48px;
  font-size: 18px;
  border-radius: 4px;
  outline: none;
  border: solid 1px #d6d6d6;
}

.detail-div2-img {
  /* border:1px solid red; */
  margin-bottom: 20px;
}

.detail-div2-imgshow {
  margin-left: 115px;
  height: 250px;
}

.detail-div2-imgshow > div:nth-of-type(1) {
  float: left;
  width: 205px;
  border: solid 1px #d4d4d4;
  height: 205px;
}

.detail-div2-imgshow > div:nth-of-type(1) > img {
  width: 205px;
  height: 205px;
}

.detail-div2-imgshow > div:nth-of-type(2) {
  float: left;
  margin-left: 100px;
  width: 350px;
}

.detail-div2-imgshow > div:nth-of-type(2) > p {
  color: gray;
}

.detail-div2-imgshow > div:nth-of-type(2) > img {
  height: 100px;
}

.detail-div2-uploadimgshow {
  height: 250px;
  margin-left: 115px;
}

.detail-div2-uploadimgshow > div:nth-of-type(1), .detail-tuxing > div > div:nth-of-type(1), .detail-tuwendetail > div > div:nth-of-type(1) {
  height: 204px;
}

.detail-div2-uploadimgshow > div:nth-of-type(1) > div, .detail-tuxing > div > div:nth-of-type(1) > div, .detail-tuwendetail > div > div:nth-of-type(1) > div {
  float: left;
}

.remark1 {
  width: 400px;
  color: red;
  margin-left: 50px;
}

.idimg {
  width: 204px;
  height: 204px;
  /* border: dotted 1px #d4d4d4; */
}

.detail-div2-uploadimgshow-div {
  margin: 15px 0 0 44px;
}

.detail-div2-uploadimgshow-div > span, .detail-tuxing-div > span, .detail-tuwendetail-div > span {
  font-size: 18px;
  color: black;
  margin-right: 200px;
  vertical-align: middle;
}

.detail-div2-uploadimgshow-div > input, .detail-tuxing-div > input, .detail-tuwendetail-div > input {
  margin-right: 16px;
  width: 16px;
  height: 16px;
  vertical-align: middle;
  cursor: pointer;
}

.detail-picksize {
  margin-top: 20px;
}

.detail-picksize > span {
  font-size: 18px;
  color: black;
  margin-right: 36px;
}

.detail-tuxing > span, .detail-tuwendetail > span {
  float: left;
  font-size: 18px;
  color: black;
  margin-right: 36px;
}

.detail-tuxing > div, .detail-tuwendetail > div {
  float: left;
}

.detail-tuxing-div, .detail-tuwendetail-div {
  margin: 30px 0 0 44px;
}

.recome-tip {
  width: 400px;
  margin-left: 50px;
  margin-bottom: 10px;
}

.recome-tip > img {
  width: 200px;
  height: 100px;
}

.recome-tip > p {
  color: gray;
}

.detail-tuwendetail {
  height: 367px;
}

.recome-next1 {
  width: 214px;
  height: 54px;
  line-height: 54px;
  border-radius: 4px;
  background-color: #EB5E00;
  cursor: pointer;
  margin: 0 auto;
  text-align: center;
  font-size: 18px;
  color: white;
}

.table {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 11;
  background: rgba(0, 0, 0, 0.1);
}

.recome-type {
  height: 370px;
  margin-bottom: 48px;
}

.recome-type-title {
  width: 380px;
  height: 60px;
  margin: 0 auto 32px;
  font-size: 28px;
  text-align: center;
  line-height: 2.14;
  color: black;
}

.recome-type-chosetype {
  padding-top: 48px;
  width: 1200px;
  height: 227px;
  margin-bottom: 64px;
  box-shadow: 3px 5px 19px -1px rgba(217, 217, 217, 0.5);
  background-color: #ffffff;
}

.recome-type-chosetype > div > div:nth-of-type(1) {
  width: 948px;
  margin: 0 auto 64px;
  height: 45px;
}

.recome-type-chosetype > div > div:nth-of-type(1) > div {
  float: left;
  width: 159px;
  height: 43px;
  color: black;
  font-size: 18px;
  margin-right: 35px;
  border-radius: 4px;
  text-align: center;
  line-height: 43px;
  cursor: pointer;
  border: solid 1px #EB5E00;
}

.recome-type-chosetype > div:nth-of-type(1) > div > div:last-child {
  margin-right: 0;
}

.guild .chosetype1, .history .chosetype2, .chose .chosetype3, .all .chosetype4, .peer .chosetype5 {
  background-color: #EB5E00 !important;
  color: #ffffff !important;
}

.recome-guild {
  margin-bottom: 48px;
}

.recome-guild-title {
  width: 340px;
  height: 60px;
  font-size: 28px;
  line-height: 2.14;
  text-align: center;
  color: black;
  margin: 0 auto 32px;
}

.recome-guild-pickguild {
  width: 1200px;
  padding: 48px 0 64px;
  box-shadow: 3px 5px 19px -1px rgba(217, 217, 217, 0.5);
  background-color: white
}

.recome-guild-pickguild-div {
  width: 715px;
  height: 50px;
  margin: 0 auto 32px;
  position: relative;
}

.recome-guild-pickguild-div > input {
  width: 620px;
  height: 48px;
  padding-left: 20px;
  border-radius: 3px;
  border: 1px solid #D6D6D6;
  outline: none;
  margin-right: 30px;
  vertical-align: middle;
}

.recome-guild-pickguild-div > span {
  display: inline-block;
  width: 42px;
  height: 42px;
  background: #EB5E00;
  border-radius: 50%;
  vertical-align: middle;
  color: #ffff;
  font-size: 28px;
  text-align: center;
  line-height: 38px;
  cursor: pointer;
}
.recome-guild-select{
  width: 610px;
  height: 510px;
  padding: 0 15px;
  background: #FFFFFF;
  overflow-y: auto;
  box-shadow: 4px 4px 16px 0px rgba(0, 0, 0, 0.09);
  position: absolute;
  top:56px;
  z-index: 10;
}
.recome-guild-select::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.recome-guild-select::-webkit-scrollbar-thumb {
  border-radius: 5px;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
}

.recome-guild-select::-webkit-scrollbar-track {
  opacity: 0;
}
.recome-guild-select-div{
  height:50px;
  border-bottom: 1px solid #D6D6D6;
  line-height: 50px;
  font-size: 18px;
  font-weight: 400;
  cursor: pointer;
  white-space:nowrap;
overflow:hidden;
text-overflow:ellipsis;
}
.recome-guild-select-div2{
  height: 100%;
  text-align: center;
  color: #FF5E00 ;
  font-size: 24px;
  line-height: 510px;
}
#minus {
  background: #D8D8D8;
}

.recome-pickhistory {
  height: 750px;
  margin-bottom: 48px;
}

.recome-pickhistory-title {
  width: 400px;
  height: 60px;
  font-size: 28px;
  line-height: 2.14;
  text-align: center;
  color: black;
  margin: 0 auto 33px;
}

.recome-pickhistory-list {
  width: 1200px;
  height: 610px;
  padding-top: 43px;
  box-shadow: 3px 5px 19px -1px rgba(217, 217, 217, 0.5);
  background-color: white;
}

.recome-pickhistory-list-div1 {
  height: 30px;
  margin-bottom: 20px;
  margin-left: 126px;
}

.recome-pickhistory-list-div1 > div {
  height: 30px;
}

.recome-pickhistory-list-div1 > div > div {
  float: left;
  width: 100px;
  height: 30px;
  border-radius: 4px;
  color: black;
  text-align: center;
  line-height: 30px;
  border: solid 1px #EB5E00;
  margin-right: 30px;
  cursor: pointer;
}

.recome-pickhistory-list-div1 > div > div:nth-of-type(3) {
  width: 400px;
  height: 30px;
  margin-left: 280px;
  border: none;
}

.recome-pickhistory-list-div1-search > input {
  float: left;
  width: 306px;
  height: 30px;
  padding-left: 12px;
  outline: none;
  border: 1px solid #d6d6d6;
  border-radius: 4px;
}

.recome-pickhistory-list-div1-search > div {
  float: left;
  width: 80px;
  height: 30px;
  font-size: 14px;
  text-align: center;
  color: white;
  border-radius: 4px;
  background-color: #EB5E00;
}

.history .historytrade, .import .importtrade {
  background-color: #EB5E00 !important;
  color: #ffffff !important;
}

.recome-pickhistory-list-div2 {
  width: 948px;
  height: 370px;
  overflow-y: auto;
  /* border:1px solid red; */
  margin: 0 auto 64px;
}

.recome-pickhistory-list-div2::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.recome-pickhistory-list-div2::-webkit-scrollbar-thumb {
  border-radius: 5px;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
}

.recome-pickhistory-list-div2::-webkit-scrollbar-track {
  opacity: 0;
}

.recome-pickhistory-list-div2 > div > div {
  float: left;
  width: 120px;
  height: 165px;
  text-align: center;
  margin-right: 65px;
  margin-bottom: 30px;
}

.recome-pickhistory-list-div2 > div > div > img {
  width: 118px;
  height: 118px;
  border: 1px solid #d6d6d6;
  cursor: pointer;
  margin-bottom: 8px;
}

.recome-pickhistory-list-div2 > div > div > div {
  font-size: 14px;
  margin-bottom: 8px;
}

.recome-pickhistory-list-div2 > div > div > p {
  font-size: 14px;
  color: #4a90e2;
  cursor: pointer;
}

.recome-pickhistory-list-watchlittle {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  top: 0;
  left: 0;
  z-index: 10;
}

.recome-pickhistory-list-watchlittle-detail {
  width: 600px;
  height: 480px;
  padding-top: 14px;
  background: #ffffff;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 300;
  text-align: start;
  transform: translate(-50%, -50%);
}

.recome-pickhistory-list-watchlittle-detail > img {
  width: 17px;
  height: 17px;
  margin: 0 0 35px 570px;
  cursor: pointer;
}

.recome-pickhistory-list-watchlittle-detail-div {
  position: absolute;
  width: 520px;
  top: 30px;
  left: 40px;
}

.recome-pickhistory-list-watchlittle-detail-div1 {
  width: 80px;
  text-align: center;
  margin: 0 auto 20px;
  font-size: 16px;
  font-weight: 400;
}

.recome-pickhistory-list-watchlittle-detail-div2 {
  height: 350px;
  overflow-y: auto;
}

.recome-pickhistory-list-watchlittle-detail-div2-onecate {
  font-size: 14px;
  margin-bottom: 8px;
  color: #323232;
}

.recome-pickhistory-list-watchlittle-detail-div2-onecate > span {
  margin-right: 20px;
}

.recome-pickhistory-list-watchlittle-detail-div2-twocate {
  margin-bottom: 14px;
}

.recome-pickhistory-list-watchlittle-detail-div2-twocate > div {
  font-size: 14px;
  margin-bottom: 14px;
  font-weight: 400;
  color: #323232;
}

.recome-pickhistory-list-watchlittle-detail-div2-twocate > span {
  display: inline-block;
  width: 122px;
  height: 24px;
  line-height: 24px;
  text-align: center;
  border-radius: 3px;
  border: 1px solid #D6D6D6;
  font-size: 14px;
  font-weight: 400;
  margin: 0 10px 14px 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.historychange, .peerchange {
  border: 1px solid #EB5E00 !important;
}

.recome-peer {
  height: 870px;
  margin-bottom: 48px;
}

.recome-peer-title {
  width: 420px;
  height: 60px;
  font-size: 28px;
  line-height: 2.14;
  text-align: center;
  color: black;
  margin: 0 auto 33px;
}

.recome-peer-list {
  width: 1200px;
  height: 660px;
  padding: 40px 0 32px;
  background: #FFFFFF;
  box-shadow: 0px 4px 16px 0px rgba(90, 90, 90, 0.09);
}

.recome-peer-searh {
  width: 645px;
  height: 50px;
  margin: 0 auto 40px;
}

.recome-peer-searh > input {
  width: 400px;
  height: 48px;
  background: #FFFFFF;
  border: 1px solid #DCDFE6;
  outline: none;
  padding-left: 15px;
  font-size: 16px;
  color: #323232;
  border-right: none;
  vertical-align: middle;
  border-left: none;
}

.recome-peer-searh > span {
  display: inline-block;
  width: 105px;
  height: 50px;
  text-align: center;
  line-height: 50px;
  background: #EB5E00;
  border-radius: 3px;
  color: #FFFFFF;
  font-size: 16px;
  cursor: pointer;
  vertical-align: middle;
}
.recome-peer-select{
  width: 124px;
}
.recome-peer-detail {
  width: 1040px;
  margin: 0 auto 60px;
  height: 450px;
  overflow-y: auto;
}

.recome-peer-detail::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.recome-peer-detail::-webkit-scrollbar-thumb {
  border-radius: 5px;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
}

.recome-peer-detail::-webkit-scrollbar-track {
  opacity: 0;
}

.recome-peer-detail > div {
  margin: 0 64px 40px 0;
  float: left;
  text-align: center;
  font-size: 14px;
}

.recome-peer-detail > div:nth-child(6n) {
  margin-right: 0;
}

.recome-peer-detail > div > div > div > img {
  width: 115px;
  height: 118px;
  border: 1px solid #D6D6D6;
}

.recome-peer-detail-div > div {
  margin-bottom: 4px;
}

.recome-peer-detail-div > div:nth-of-type(1) {
  margin-bottom: 10px;
  position: relative;
  cursor: pointer;
}

.recome-peer-detail-div > div:nth-of-type(2) {
  color: #323232;
  font-size: 16px;
  width: 110px;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.recome-peer-detail-div > div:nth-of-type(1) > span {
  position: absolute;
  bottom: 12px;
  right: 6px;
  display: inline-block;
  width: 78px;
  z-index: 10;
  height: 18px;
  background: #D6D6D6;
  border-radius: 9px;
  font-size: 12px;
  color: #EB5E00;
}

.recome-peer-detail-div > p {
  cursor: pointer;
  color: #1777FF;
}

.recome-peer-list-watchlittle-detail-div1 {
  width: 520px;
  text-align: center;
  margin: 0 auto 20px;
  font-size: 16px;
  font-weight: 400;
}


.recome-recomed {
  height: 1190px;
}

.recomed1 {
  width: 300px;
  height: 60px;
  font-size: 28px;
  line-height: 2.14;
  text-align: center;
  color: black;
  margin: 0 auto 32px;
}

.recomed2 {
  width: 1200px;
  height: 1050px;
  padding-top: 48px;
  box-shadow: 3px 5px 19px -1px rgba(217, 217, 217, 0.5);
  background-color: white;
}

.recomed2-bigdiv {
  width: 948px;
  height: 868px;
  border: solid 1px #e1e1e1;
  margin: 0 auto;
}

.recomed2-bigdiv > div {
  float: left;
  height: 868px;
}

.recomed2-bigdiv-left {
  width: 317px;
  border-right: solid 1px #e1e1e1;
}

.recomed2-bigdiv-left-search {
  width: 270px;
  height: 36px;
  margin: 16px auto 23px;
  position: relative;
}

.recomed2-bigdiv-left-search > input {
  width: 240px;
  height: 34px;
  padding-left: 27px;
  border-radius: 4px;
  outline: none;
  border: solid 1px #e1e1e1;
}

.recomed2-bigdiv-left-search > img {
  width: 13px;
  height: 13px;
  position: absolute;
  top: 11px;
  left: 9px;
}

.recomed2-bigdiv-left-three-link {
  width: 295x;
  height: 750px;
  margin-left: 20px;
  overflow-y: auto;
}

.recomed2-bigdiv-left-three-link::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.recomed2-bigdiv-left-three-link::-webkit-scrollbar-thumb {
  border-radius: 5px;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
}

.recomed2-bigdiv-left-three-link::-webkit-scrollbar-track {
  opacity: 0;
}

.el-icon-caret-right, .el-icon-caret-bottom {
  color: #EB5E00;
  font-size: 20px;
  vertical-align: middle;
  margin-right: 14px;
}

.link-div1 {
  margin-bottom: 13px;
  font-size: 16px;
  width: 285px;
  height: 25px;
  cursor: pointer;
  color: #333333;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.link-div2 {
  margin-left: 25px;
  margin-bottom: 10px;
  font-size: 14px;
  width: 260px;
  cursor: pointer;
  color: #333333;
}

.link-div2 > div {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.link-div3 {
  width: 200px;
  margin-left: 60px;
  margin-top: 10px;
  font-size: 12px;
  cursor: pointer;
  color: #333333;
}

.recomed2-bigdiv-right {
  width: 630px;
}

.recomed2-bigdiv-right-top {
  width: 630px;
  height: 60px;
  border-bottom: solid 1px #e1e1e1;
  background-color: #fafafa;
  position: relative;
}

.recomed2-bigdiv-right-top > div {
  position: absolute;
}

.recomed2-bigdiv-right-top > div:nth-of-type(1) {
  font-size: 20px;
  color: black;
  top: 16px;
  left: 36px;
}

.recomed2-bigdiv-right-top > div:nth-of-type(2) {
  font-size: 14px;
  color: #EB5E00;
  top: 21px;
  left: 236px;
  cursor: pointer;
}

.recomed2-bigdiv-right-top > div:nth-of-type(3) {
  width: 430px;
  height: 200px;
  box-shadow: 0 0 30px -1px rgba(217, 217, 217, 0.5);
  background-color: #ffffff;
  position: absolute;
  left: 100px;
  top: 60px;
  color: #333333;
  padding: 10px 10px 0 10px;
  overflow-y: auto;
  z-index: 10;
}

.recomed2-bigdiv-right-top > div:nth-of-type(3) > p {
  font-size: 12px;
  margin-bottom: 10px;
}

.recomed2-bigdiv-right-top > div:nth-of-type(3)::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.recomed2-bigdiv-right-top > div:nth-of-type(3)::-webkit-scrollbar-thumb {
  border-radius: 5px;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
}

.recomed2-bigdiv-right-top > div:nth-of-type(3)::-webkit-scrollbar-track {
  opacity: 0;
}

.recomed2-bigdiv-right-showtype {
  height: 710px;
  overflow-y: auto;
  border-bottom: solid 1px #e1e1e1;
}

.recomed2-bigdiv-right-showtype::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.recomed2-bigdiv-right-showtype::-webkit-scrollbar-thumb {
  border-radius: 5px;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
}

.recomed2-bigdiv-right-showtype::-webkit-scrollbar-track {
  opacity: 0;
}

.recomed2-bigdiv-right-showtype-cirl {
  border-bottom: dotted 1px #e1e1e1;
  padding: 24px 0 0 36px;
}

.div1 {
  font-size: 18px;
  color: #333333;
  margin-bottom: 10px;
}

.div1 > input {
  width: 16px;
  height: 16px;
  vertical-align: middle;
  margin-right: 10px;
  cursor: pointer;
}

.div1 > span {
  vertical-align: middle;
}

.div1 > span:nth-of-type(1) {
  display: inline-block;
  width: 138px;
}

.div1 > span:nth-of-type(2) {
  font-size: 12px;
  color: #999999;
  margin-left: 10px;
}

.div1-span1 {
  font-size: 12px;
  margin: 0 4px 0 10px;
}

.div1-span2 {
  font-size: 12px;
  color: #FF5E00;
}

.div1 > div {
  float: right;
  margin-right: 100px;
  margin-top: 3px;
}

.second {
  margin-bottom: 4px;
}

.container {
  margin-bottom: 18px;
}

.div2 {
  height: 22px;
  margin: 0 0 8px 16px;
}

.div2 > div {
  float: left;
}

.div2 > div:nth-of-type(1) > input {
  float: left;
  width: 16px;
  height: 16px;
  vertical-align: middle;
  margin-right: 14px;
  cursor: pointer;
  margin-top: 3px;
}

.div2 > div:nth-of-type(1) > div {
  width: 170px;
  float: left;
  font-size: 16px;
  color: #333333;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.div2 > div:nth-of-type(2) {
  float: left;
  margin-left: 7px;
}

.div2 > div:nth-of-type(2) > span {
  float: left;
  margin-top: 2px;
}

.div2 > div:nth-of-type(2) > div:nth-of-type(1) {
  float: left;
  height: 19px;
  margin: 0 0 0 8px;
}

.div2 > div:nth-of-type(2) > div:nth-of-type(2) {
  float: left;
  width: 96px;
  height: 19px;
  border: 1px solid red;
  font-size: 14px;
  color: #EB5E00;
  text-align: center;
  line-height: 19px;
  border-radius: 4px;
  cursor: pointer;
  background: #fbefe9;
  margin-left: 35px;
}

.div3 {
  margin-left: 16px;
}

.div3 > div {
  float: left;
  min-height: 33px;
  padding-left: 8px;
  border: dotted 1px #d6d6d6;
  margin: 0 20px 8px 0;
  padding-right:30px;
  position:relative;
}

.div3 > div > div {
  float: left;
}


.div3 > div > div:nth-of-type(1) {
  min-height: 33px;
  text-align: left;
  line-height: 33px;
  font-size: 14px;
  font-weight: 300;
  padding-right: 8px;
  color: #333333;
  font-size: 14px;
  font-weight: 300;
}

.div3 > div > div:nth-of-type(2) {
  width: 35px;
  height:100%;
  display:flex;
  /* align-items: center; */
  justify-content: center;
  border-left: 1px dotted #d6d6d6;
  background-color: #f5f5f5;
  font-size: 20px;
  color: #d6d6d6;
  text-align: center;
  cursor: pointer;
  position:absolute;
  right:0;
  top:50%;
  transform: translateY(-50%);
}
.self1{
  border-color: #0075FF!important;
}

.self2{
  color: #0075FF!important;
}
.self3{
  background: #0075FF!important;
  color: #fff!important;
}
.includeClass{
  border-color: #EB5E00!important;
  color: #EB5E00!important;
  background: #fbefe9!important;
}
#clear {
  width: 0px;
  height: 0px;
  clear: both;
  border: none;
  margin: 0;
}

.type-box-right-showtype-choose {
  height: 40px;
  margin: 24px 0 20px;
}

.type-box-right-showtype-choose-div1 {
  height: 20px;
  margin-bottom: 10px;
}

.type-box-right-showtype-choose-div1 > div {
  float: left;
}

.type-box-right-showtype-choose-div1 > div:nth-of-type(1) {
  font-size: 14px;
  font-weight: 300;
  margin: 0 190px 0 16px;
}

.type-box-right-showtype-choose-div1 > div:nth-of-type(1) > span {
  color: #EB5E00;
}

.type-box-right-showtype-choose-div1 > div:nth-of-type(2) > span {
  font-size: 14px;
  font-weight: 300;
}

.type-box-right-showtype-choose-div1 > div:nth-of-type(2) > span:nth-of-type(2) {
  color: #EB5E00;
}

.type-box-right-showtype-addcart {
  height: 40px;
  margin: 23px 0 0 35px;
}

.type-box-right-showtype-addcart > div:nth-of-type(1) {
  float: left;
  margin-top: 10px;
}

.type-box-right-showtype-addcart > div:nth-of-type(1) > input {
  width: 16px;
  height: 16px;
  vertical-align: middle;
  margin-right: 14px;
  cursor: pointer;
}

.type-box-right-showtype-addcart > div:nth-of-type(1) > span {
  vertical-align: middle;
  font-size: 16px;
  color: #333333;
}

#type-box-right-showtype-addcart-del {
  float: right;
  width: 240px;
  height: 39px;
}

#type-box-right-showtype-addcart-del > div {
  float: left;;
  height: 39px;
  cursor: pointer;
  line-height: 39px;
  margin-right: 50px;
}

#type-box-right-showtype-addcart-del > div > i {
  vertical-align: middle;
}

#type-box-right-showtype-addcart-del > div > span {
  margin-left: 10px;
  vertical-align: middle;
}

.setActive {
  color: #EB5E00 !important;
}

.type-box-total {
  width: 948px;
  height: 71px;
  padding-top: 32px;
  border: solid 1px #e1e1e1;
  margin: 0 auto;
  border-top: none;
}

.type-box-total > div {
  float: right;
}

.type-box-total > div:nth-of-type(1) {
  width: 146px;
  height: 39px;
  border-radius: 4px;
  background-color: #EB5E00;
  font-size: 16px;
  color: #ffffff;
  text-align: center;
  line-height: 39px;
  cursor: pointer;
  margin-right: 36px;
}

.type-box-total > div:nth-of-type(2) {
  width: 144px;
  height: 37px;
  border-radius: 4px;
  border: solid 1px #EB5E00;
  background-color: #fbefe9;
  font-size: 16px;
  color: #EB5E00;
  text-align: center;
  margin-right: 12px;
  line-height: 39px;
  cursor: pointer;
}

.type-box-total > div:nth-of-type(3) {
  margin-right: 36px;
  height: 38px;
}

.type-box-total > div:nth-of-type(3) > span:nth-of-type(1) {
  font-size: 14px;
  font-weight: 300;
  margin: 20px 8px 0 0;
}

.type-box-total > div:nth-of-type(3) > span:nth-of-type(2) {
  font-size: 32px;
  font-weight: 600;
  color: #EB5E00;
}

</style>
<style>
  .detail-picksize>div>div>input{
      width: 830px;
      height: 48px!important;
      border: solid 1px #d4d4d4;
      font-size: 24px;
      color: #666666;
  }
  .detail-picksize .el-select .el-input.is-focus .el-input__inner,.el-select .el-input__inner:focus{
      border-color: #C0C4CC;
  }
  .el-select-dropdown__item.selected{
       color: #EB5E00;
   }
   .el-icon-delete{
        cursor: pointer;
        margin-left: 10px;
        vertical-align: middle;
        line-height: normal;
    }
    .el-rate__icon{
        font-size: 14px!important;
    }
    .recome-peer-searh .el-input__inner{
      height: 50px!important;
      font-size: 16px!important;
      vertical-align: middle;
    }
</style>

